import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import {gql, useMutation, useQuery} from '@apollo/client';
import { Cookies } from 'react-cookie';

const CREATEWORKSHOP = gql`mutation CreateWorkshop($input: createWorkshopInput) {
    createWorkshop(input: $input) {
      name
      description
      id
      createAt
      invitationCode
      participantAmount
    }
  }`

const GETALLWORKSHOPS = gql`query GetAllWorkshops {
    getAllWorkshops {
      invitationCode
    }
  }`

function generateUniqueCode(existingCodes) {
    let code;
    do {
        code = Math.floor(1000 + Math.random() * 9000); // Generates a random 4-digit code between 1000 and 9999
    } while (existingCodes.includes(code));
    return code;
}

export default function CreateWorkshop() {

    const [createWorkshopReq] = useMutation(CREATEWORKSHOP);
    const navigate = useNavigate();
    const [workshopInput, setWorkshopInput] = useState({
        name: "",
        description: "",
        participantAmount: 0
        }
    );
    const cookies = new Cookies();
    const user_data = cookies.get("userData")
    const {data} = useQuery(GETALLWORKSHOPS)
    const [existingCodes, setExistingCodes] = useState([])

    useEffect(() => {
        if(data && existingCodes.length === 0){
            setExistingCodes(data.getAllWorkshops.map(workshop => workshop.invitationCode))
        }
    }, [])
    
    const createWorkshop = async() => {
        const input = {
            name: workshopInput.name,
            description: workshopInput.description,
            workshopOwnerUserId: user_data.id,
            invitationCode: generateUniqueCode(existingCodes).toString(),
            participantAmount: Math.floor(workshopInput.participantAmount)
        }
       
        const res = await createWorkshopReq({variables: {"input": input}})

        if(res){
            navigate('../showWorkshop')
        }
      }

  const onChange = (e, key) => {
    setWorkshopInput(prevState => ({
        ...prevState,
        [key]: e.target.value
      }));
    }

  return (
    <Row className='defaultPage SignLoginMainRow'>
        <Col style={{display:'flex', justifyContent:'center', alignItems:'center', width:'50%', backgroundColor:"rgba(17, 22, 35, 0)"}}>
            <Row>
                <Col style={{backgroundColor:"rgba(9,9,9,0.6)",padding:'50px', borderRadius:'25px', color: "white"}}>
                    
                    <Row>
                    <Row><Col><h1><b>Welcome to RealityFlow</b></h1></Col></Row>
                    
                    <br></br>
                    <Row><Col><h3><i>Create Your Workshop</i></h3></Col></Row>
                        <Col>
                            <Form >

                                <Form.Group controlId="formBasicName">
                                    <Form.Label>Workshop Name</Form.Label>
                                    <Form.Text style={{color:"lightgray"}}>
                                    <Form.Control placeholder="Enter Workshop Name" onChange = {e => onChange(e, 'name')}/>
                                    </Form.Text>
                                </Form.Group>
                                <br></br>

                                <Form.Group controlId="formBasicInput">
                                    <Form.Label>Workshop Description</Form.Label>
                                    <Form.Control 
                                    type="text" 
                                    placeholder="Enter Description" 
                                    onChange = {e => onChange(e, 'description')}
                                    />
                                </Form.Group>
                                <br></br>

                                <Form.Group controlId="formBasicInput">
                                    <Form.Label>Participant Amount (Headset count)</Form.Label>
                                    <Form.Control 
                                    type="text" 
                                    placeholder="Enter count" 
                                    onChange = {e => onChange(e, 'participantAmount')}
                                    />
                                </Form.Group>
                                <br></br>

                                <Button style={{marginRight: '3%'}} className = "defaultButton" variant="primary" onClick={() => navigate('../showWorkshop')}>
                                    Back
                                </Button>
                                <Button className = "defaultButton" variant="primary" onClick={() => createWorkshop()}>
                                    Create Workshop
                                </Button>
                            </Form>
                            
                            <br></br>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    </Row>
)
}
