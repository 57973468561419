
import { Row, Col, Form, Button } from 'react-bootstrap';
import { gql, useQuery } from '@apollo/client'
import UserProjectsDisplay from '../Components/UserProjectsDisplay';
import { Link, useNavigate } from "react-router-dom";
import RfNavbar from "../Components/Navbar";
import { Cookies } from 'react-cookie';
import { headerWithToken, kickUser } from '../Components/Helpers/utils'
import FriendAddButton from "../Components/FriendComponents/FriendAddButton";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ProfilePicture from "../Components/ProfilePicture";
import { useEffect, useState, useRef, useCallback } from 'react';
import GoogleDrivePicker from '../Components/GoogleDrivePicker';
import { FcCheckmark } from "react-icons/fc";
import ModelUploader from '../Components/ModelUploader';
import ModelViewer from '../Components/ModelViewer';

const allList = [  //list from registry but replace with API calls
  {
    name: "",
    url: "http://api2:4000",
    port: 0,
    description: "",
    ip: "",
    status: 'online',
  },
  {
    name: "",
    url: "http://api2:4001",
    port: 0,
    description: "",
    ip: "",
    status: 'online',
  },
  {
    name: "",
    url: "http://api2:4002",
    port: 0,
    description: "",
    ip: "",
    status: 'online',
  },
  {
    name: "",
    url: "http://api2:4003",
    port: 0,
    description: "",
    ip: "",
    status: 'online',
  }
]

export default function AccountPage() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const accesstoken = cookies.get("accessToken")
  const user_data = cookies.get("userData")
  const queryParams = new URLSearchParams(window.location.search);
  const account = queryParams.get("acc");
  const [subcribeServerList, setSubcribeServerList] = useState(localStorage.getItem('subscribedServers') ? JSON.parse(localStorage.getItem('subscribedServers')) : []);
  const [showCheckmark, setCheckmark] = useState(false);
  const [enable, setEnable] = useState(false);
  const [selectedModel, setSelectedModel] = useState(undefined);
  const [reloadModelViewer, setReloadModelViewer] = useState(false);
  const GETACCOUNT = gql`query GetUserById($getUserByIdId: String!) {
        getUserById(id: $getUserByIdId) {
          profileImg
          email
          firstName
          lastName
          username
          bio
          userType
          projectsOwned {
            projectName
            id
            gallery
          }
          projectsJoined {
            projectName
            id
            gallery
          }
          projectsCoOwned {
            projectName
            id
            gallery
          }
          modelsOwned {
            id
            userId
            name
            downloadURL
            thumbnailURL
            triangles
            followersIds
          }
          modelsFollowed {
            id
            userId
            name
            downloadURL
            thumbnailURL
            triangles
            followersIds
          }
          friendManagement {
            friends {
              id
            }
          }
        }
      }`
  useEffect(() => {
    if (accesstoken == null || user_data == null) {
      console.log("NO ACCESSTOKEN")
      navigate('/')
    }
  });
  const { data, error, refetch } = useQuery(GETACCOUNT,
    {
      variables: { "getUserByIdId": account },
      context: headerWithToken(accesstoken)
    });

  if (error) {
    if (error.message === 'Not Authenticated') {
      kickUser(navigate, cookies);
    }
  }
  const Item = props => {
    const { title, id, img } = props
  
    return (
      <Link to={{
        pathname: "../roomdetailpage",
        search: `?p=${id}`
      }}>
        <div>
          <img
            className="d-block w-100"
            src={img ? img : "https://via.placeholder.com/150"}
            alt={"slide " + id}
          />
          <h3>{title}</h3>
        </div>
      </Link>
    )
  };
  const Model = ({model}) => {
    const onClick = _ => {
      setSelectedModel(model);
      setReloadModelViewer(true);
    }
    return (
      <div key={model.id} onClick={onClick} className="account-page--model">
        <img className="d-block w-100" src={model.thumbnailURL}
          style={{backgroundColor: "lightgray", width: "100%", aspectRatio: 1}} />
        <h3>{model.name}</h3>
      </div>
    );
  }

  const [showPopup, setShowPopup] = useState(false);

  const [modelViewInfo, setModelViewInfo] = useState({rows: 4})
  const modelViewShowMoreRef = useRef(null);

  const handleAddModelClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleServerChange = (selectedServer) => {
    setEnable(true)

    if(subcribeServerList.filter(i => i.url === selectedServer.url).length > 0)
      setSubcribeServerList(subcribeServerList.filter(item => item.url !== selectedServer.url))
    else 
      setSubcribeServerList([...subcribeServerList, selectedServer])
  }

  // Live update for account information
  setInterval(() => { refetch(); }, 500);
  
  const account_data = (data) ? data.getUserById : {
    'profileImg': '#FFFFFF Smile.png',
    'username': 'Username',
    'bio': 'bio',
  };
  const onServerSelectSubmit = async (e) => {
    e.preventDefault();
    setEnable(false)
    
    localStorage.removeItem('subscribedServers');   //clear already existing ones otherwise it will accumulate instead of replacing 

    localStorage.setItem('subscribedServers', JSON.stringify(subcribeServerList));

    setCheckmark(true)

    setTimeout(() => {
      setCheckmark(false)
    }, 2000);
  }

    return (
        <>
            <RfNavbar></RfNavbar>
            <ModelViewer model={selectedModel} reload={reloadModelViewer}
              setReload={setReloadModelViewer} viewer={user_data?.id} />
            <Row className="defaultPage" style={{padding:'10%'}}>
            <Col>
                <Row  >
                  <Col >
                    <Row><Col><ProfilePicture profileImg = {account_data.profileImg} useClass = {"accountImageSettings prevent-select"}/>{/*<img src={images[account_data.profileImg]} className='accountImageSettings prevent-select'/>*/}</Col></Row>
                    <Row><Col style={{paddingTop:'1%'}}><h1>{ account_data.username}</h1></Col></Row>


                  </Col>
                    <Col style={{maxWidth:'40%'}}>


                        <Row style={{border:'solid 1px white', borderRadius:'10px', minHeight:'50%'}}>
                          <Col >
                            <h2>About Me</h2>
                            <p style={{textAlign:'left'}}>{account_data.bio}</p>
                          </Col>
                        </Row>
                        <Row style={{paddingTop:'5%'}}>
                            <FriendAddButton userId = {(user_data) ? user_data.id : null} friendId = {account} accesstoken = {accesstoken}/>
                        </Row>
                        <Row style={{minHeight:'10%'}}></Row>
                        <Row><Col style={{marginTop:'2%', border:'solid 1px white', borderRadius:'10px'}}>
                            <div>Owner of {(data) ? account_data.projectsOwned.length : 0} Projects</div>
                            <div>Co-Owner of {(data) ? account_data.projectsCoOwned.length: 0} Projects</div>
                            <div>Has joined {(data) ? account_data.projectsJoined.length : 0} Projects</div>
                            <div>Owner of {(data) ? account_data.modelsOwned.length : 0} Models</div>
                            <div>Following {(data) ? account_data.modelsFollowed?.length : 0} Models</div>
                        </Col></Row>
                    </Col>
                </Row>
                <Row style={{marginTop:'15%', paddingBottom:'5%'}}>
                   <h2>Assets</h2>
                {/* <UserProjectsDisplay colCount={3} md={4}>
                    { projectsList.length > 0 ? projectsList.map(item => <Item key={item.id} id={item.id} title={item.projectName}/>) : [<p>No projects are found. <Link to="/createproject" >Click Here</Link> to create one</p> ] }
                </UserProjectsDisplay> */}
                        <Tabs className='tabulo'>
              {account_data.userType === 'admin' ? <Tab eventKey="servers" title="Servers">
                <Row>
                  <Col style={{ marginTop: '2%' }}>
                  <Form onSubmit={onServerSelectSubmit}>   
                        {allList.length > 0 ? 
                        <>
                          {allList.map((server, index) => (
                          <div key={`check-${server.url}`} style ={{ marginLeft: "15px"}}>
                            <Form.Check 
                            type={"checkbox"}
                            key={`${index}`}
                            label={server.url}
                            checked={subcribeServerList.filter(i => i.url == server.url).length > 0}
                            style={{ marginRight: "1050px", fontSize: "25px"}}
                            onChange = {() => handleServerChange(server)}
                            />
                          </div>
                        ))}
                        <Button className = "defaultButton" variant="primary" type="submit" disabled={!enable}>
                            Subscribe
                        </Button>
                        {showCheckmark ? <FcCheckmark style={{ fontSize: "40px"}}/> : null}
                        </>
                         : <p>No Servers to Subscribe to. Contact Your Administrator</p>}
                   </Form>
                  </Col>
                </Row>
              </Tab> : null}
            <Tab eventKey="owned" title="Owned Projects">
                <Row >
                  <Col style={{ marginTop: '2%' }}>
                    {(data) ? (
                      <UserProjectsDisplay colCount={3} md={4}>
                        {account_data.projectsOwned.length > 0 ? account_data.projectsOwned.map(item => <Item key={item.id} id={item.id} title={item.projectName} img={item.gallery[0]} />) : [<p>No projects are found. <Link to="/createproject" >Click Here</Link> to create one</p>]}
                      </UserProjectsDisplay>) : null}
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="coowned" title="Co-Owned Projects">
                <Row>
                  <Col style={{ marginTop: '2%' }}>
                    {(data) ? (
                      <UserProjectsDisplay colCount={3} md={4}>
                        {account_data.projectsCoOwned.length > 0 ? account_data.projectsCoOwned.map(item => <Item key={item.id} id={item.id} title={item.projectName} img={item.gallery[0]} />) : [<p>No projects are found. <Link to="/createproject" >Click Here</Link> to create one</p>]}
                      </UserProjectsDisplay>) : null}
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="joined" title="Joined Projects">
                <Row>
                  <Col style={{ marginTop: '2%' }}>
                    {(data) ? (
                      <UserProjectsDisplay colCount={3} md={4}>
                        {account_data.projectsJoined.length > 0 ? account_data.projectsJoined.map(item => <Item key={item.id} id={item.id} title={item.projectName} img={item.gallery[0]} />) : [<p>This user hasn't joined any projects</p>]}
                      </UserProjectsDisplay>) : null}
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="ownedModels" title="Owned Models">
                <Row style={{ marginTop: "2%" }}>
                  {/* Add model button */}
                  {
                    user_data?.id == account ?
                      <>
                        <input type="button"
                          style={{margin: "0 auto 2% auto", width: "50%"}}
                          value="Add new model"
                          onClick={handleAddModelClick}
                        />
                        { showPopup
                          && <ModelUploader onClose={handleClosePopup} /> }
                      </>
                      : null
                  }

                  {/* Render models */}
                  {
                    data && account_data.modelsOwned.length > 0 ? (
                      <>
                        <UserProjectsDisplay colCount={4} rowLimit ={modelViewInfo.rows}>
                          {account_data.modelsOwned.map(item => <Model model={item} />)}
                        </UserProjectsDisplay>
                        {account_data.modelsOwned.length > 4 * modelViewInfo.rows
                          ? <input type="button" value="Show More"
                              onClick={_ => {
                                setModelViewInfo({...modelViewInfo,
                                  rows: modelViewInfo.rows + 4,
                                });
                              }} />
                          : null}
                      </>
                    ) : <p>No owned models.</p>
                  }
                </Row>
              </Tab>
              <Tab eventKey="followedModels" title="Followed Models">
                <Row style={{ marginTop: "2%" }}>
                  {/* Render models */}
                  {
                    data && account_data.modelsFollowed.length > 0 ? (
                      <>
                        <UserProjectsDisplay colCount={4} rowLimit ={modelViewInfo.rows}>
                          {account_data.modelsFollowed.map(item => <Model model={item} />)}
                        </UserProjectsDisplay>
                        {account_data.modelsFollowed.length > 4 * modelViewInfo.rows
                          ? <input type="button" value="Show More"
                              onClick={_ => {
                                setModelViewInfo({...modelViewInfo,
                                  rows: modelViewInfo.rows + 4,
                                });
                              }} />
                          : null}
                      </>
                    ) : <p>No owned models.</p>
                  }
                </Row>
              </Tab>
            </Tabs>
          </Row>
        </Col>
      </Row>
    </>
  )
}
