import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';


export function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  
  return (
    
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <img alt="item-img" src={props.image} style={{width:'200px', height:'200px', border:'solid 2px #027bff', marginBottom:'10%', borderRadius:'20%'}}/>
  </div>
  );
}