import jwtDecode from 'jwt-decode';
export function handleToken(accessToken){
    try {
        const user_data = jwtDecode(accessToken);
        return user_data
    } catch(err) {
        console.log(err.message)
        window.location.href('./login')
    }
}
export function headerWithToken(accessToken){
    return { headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
    }
      };
    
}
export function kickUser(navigate, cookies){
    cookies.remove("accessToken");
    cookies.remove("userData");
    navigate("/");
}
