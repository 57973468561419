
import {Row, Col} from 'react-bootstrap';


export default function PasswordRecovery() {
   
    return (
        <>
        <Row className='defaultPage SignLoginMainRow'>
            <Col style={{display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:"rgba(17, 22, 35, 0)"}}>
                <Row>
                    <Col style={{backgroundColor:"rgba(9,9,9,0.6)",padding:'50px', borderRadius:'25px', color: "white", maxWidth: "500px"}}>
                        
                        <Row>
                        <Row><Col><h1><b>Welcome to RealityFlow</b></h1></Col></Row>
                        <br></br>
                        <Row><Col><h4>Password Recovery</h4></Col></Row>
                        <br></br>
                        <Row><Col><p>Your password has been reset</p></Col></Row>
                        <br></br>
                        <Row><Col><a href = "../login">Click here to login</a></Col></Row>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
        </>
    )
}