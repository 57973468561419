import { useNavigate } from "react-router-dom";
import {Row, Col, Button, Form} from 'react-bootstrap';
import {gql, useMutation} from '@apollo/client';
import { useState } from "react";

export default function ResetPasswordPage() {
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token"); 

    const RESETPASS = gql `mutation ResetPassword($input: ResetPasswordInput) {
        resetPassword(input: $input) {
          id
        }
      }`

    const [resetPass, {data, error}] = useMutation(RESETPASS)
    const [values, setValues] = useState({});
    const [matchError, setMatchError] = useState({})

    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setValues({...values, [name]: value });
    }
    function validate(){
            setMatchError({...matchError, "message": ""})
            const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/i;
            if(values.password !== values.confirmPassword){
                setMatchError({...matchError, "message": "Passwords do not match"})
                return false;
            } 
            else if(!passRegex.test(values.password))
            {
                setMatchError({...matchError, "message": "Your password requires a minimum of eight characters, at least one uppercase letter, one lowercase letter and one number"})
                return false;
            }
            return true;
    }
    const onSubmit = (e) => {
        e.preventDefault();
        if(validate()){
            resetPass({variables: {"input": {"token": token, "newPassword": values.password}}})
        }
    }
    if(data){
        navigate("../passwordrecovery");
    }
    return (
        <Row className='defaultPage SignLoginMainRow'>
            <Col style={{display:'flex', justifyContent:'center', alignItems:'center', width:'50%', backgroundColor:"rgba(17, 22, 35, 0)"}}>
                <Row>
                    <Col style={{backgroundColor:"rgba(9,9,9,0.6)",padding:'50px', borderRadius:'25px', color: "white"}}>
                        
                        <Row>
                        <br></br>
                        <Row><Col><h1><b>Reset Password</b></h1></Col></Row>
                            <Col>
                                <Form  onSubmit= {onSubmit}>
                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>New Password</Form.Label>
                                        <Form.Control type="password" name = "password" placeholder="Password" onChange={onChange} />
                                    </Form.Group>
                                    <br></br>

                                    <Form.Group controlId="formBasicConfirmPassword">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control type="password" name = "confirmPassword" placeholder="Confirm Password" onChange={onChange}/>
                                    </Form.Group>
                                    <div style = {{color: "red" }}>{matchError.message}</div>
                                    {error && <div style ={{color:"red"}}>{error.message}</div>}
                                    <br></br>
                                    <Button className = "defaultButton" variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </Form>
                                <br></br>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
