import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import { Link } from 'react-router-dom';

export default function RfDiscoveryCarousel ({projects, title}) {
    let slideCount = 0;

    if (projects.length % 4 === 0)
        slideCount = projects.length / 4
    else
        slideCount = Math.floor(projects.length / 4) + 1;
        
    let index = 0;

        const renderCarousel = () => {
            let slides = []
            console.log(slideCount)
            for(let slide = 0; slide < slideCount; slide++) {
                slides.push(
                    <Carousel.Item key={slide}>
                        <Row>
                            {
                                renderItems()
                            }
                        </Row>
                    </Carousel.Item>
                );
            }
            return slides;
        }

        const renderItems = () => {
            let items = []
            for(let item = 0; item < 4; item++) {
                if (index >= projects.length) {
                   items.push(<Col key={index}></Col>);
                }
                else {
                    items.push(<Col className='text-truncate' key={projects[index].id}><Link to={`../roomdetailpage?p=${projects[index].id}`}><img
                                    className="d-block w-100"
                                    src={`/images/${projects[index].gallery[0]}`}
                                    alt={"slide " + projects[index].gallery[0]}
                                />
                                </Link><h2 style={{color:'white', textDecoration: 'none', textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden'}}>{projects[index].projectName}</h2>
                    </Col>);
                }

                index++;
            }
            
            return items;
        }

        return (<>
        <h2 style={{textAlign:'left', marginLeft:'5%', paddingTop:'3%'}} >{title}</h2>
        <svg className="settingsVector" style={{ width:'90%', marginBottom:'2%'}}></svg>
                <Carousel className='DiscoveryCarousel' indicators={false} interval={null}>
                {renderCarousel()}
        </Carousel>
        </>
    )
}
