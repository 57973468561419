import React from 'react';
import {gql, useMutation} from '@apollo/client';
import { headerWithToken } from '../Helpers/utils';
import { Button } from 'react-bootstrap';

const REMOVEFRIEND = gql`mutation UnFriend($input: FriendInput) {
  unFriend(input: $input) {
    id
  }
}`

const RemoveFriend = ({userId, friendId, accesstoken}) =>{
    const [doRemoveFriend] = useMutation(REMOVEFRIEND)
    const doRemove = (e) =>{
        doRemoveFriend({variables: {"input": {"acceptorId": userId, "requestorId": friendId}}, context: headerWithToken(accesstoken)})
        window.location.reload(false);
    }
    return( 
        <Button onClick = {doRemove}> Remove Friend </Button>
    );
}
export default RemoveFriend;