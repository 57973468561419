import React from 'react';
import {gql, useMutation} from '@apollo/client';
import { headerWithToken } from '../Helpers/utils';
import { Button } from 'react-bootstrap';

const FRIENDACCEPT = gql`mutation AddFriend($input: FriendInput) {
    addFriend(input: $input) {
      id
    }
  }`

const FriendAccept = ({userId, friendId, accesstoken, renderObj}) =>{
    const [acceptFriend, {data, loading}] = useMutation(FRIENDACCEPT)
    const doAccept = () =>{
        
        acceptFriend({variables: {"input": {"acceptorId": userId, "requestorId": friendId}}, context: headerWithToken(accesstoken)})
        //const newRender = !renderObj.isRender
        //renderObj.setRender(newRender)
    }
    if(loading){
        return("Accepting...")
    }
    if(data){
        const newRender = !renderObj.isRender
        renderObj.setRender(newRender)
    }

    return( 
        <Button className='acceptButton' onClick = {doAccept}>
                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>
        </Button>
    );
}
export default FriendAccept;