import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import RfNavbar from "../Components/Navbar";
import UserProjectsDisplay from "../Components/UserProjectsDisplay";
import { gql, useQuery } from "@apollo/client";
import { Cookies } from "react-cookie";
import { headerWithToken, kickUser } from "../Components/Helpers/utils";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const GETWORKSHOPBYCODE = gql`
  query GetWorkshop($code: String!) {
    getWorkshopByCode(code: $code) {
      name
      id
      description
      participantAmount
      participants{
        id
        username
        email
        firstName
        lastName
      }
    workshopOwner{
        id
        username
        email
        firstName
        lastName
    }
      instructors{
        id
        username
        email
        firstName
        lastName
      }
    }
  }
`;

export default function ParticipantsList() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const accesstoken = cookies.get("accessToken");
  const user_data = cookies.get("userData");
  const location = useLocation();
  const { code } = location.state || {};
  const [workshopData, setWorkshopData] = useState(null);

  const { data, error } = useQuery(GETWORKSHOPBYCODE, {
    variables: { code: code },
  });

  useEffect(() => {
    if (data) setWorkshopData(data.getWorkshopByCode);
  }, [data]);

  useEffect(() => {
    if (!accesstoken || !user_data) {
      console.log("NO ACCESSTOKEN");
      navigate("/");
    }
  }, [accesstoken, user_data, navigate]);

  useEffect(() => {
    if (error && error.message === "Not Authenticated") {
      kickUser(navigate, cookies);
    }
  }, [error, navigate, cookies]);

  const Item = ({ title, id, img }) => (
    <Link to={`/monitorWorkshop?code=${code ?? 0}&participantId=${id}`}>
      <div>
        <img
          className="d-block w-100"
          src={require(`../../public/images/${
            img ? img : "defaultThumbnail.jpeg"
          }`)}
          alt={`slide ${id}`}
        />
        <h3>{title}</h3>
      </div>
    </Link>
  );
  return (
    <>
      <RfNavbar />
      <Tabs className="tabulo justify-content-center" >
        <Tab eventKey="Participants" title="Participants">
          <Row>
            <Col className="defaultPage" style={{ padding: "2%" }}>
              <Row>
                <Col>
                  <h1>Participants</h1>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'right' }}>
                  <Link to={`${process.env.REACT_APP_PUBLIC_URL ?? "http://localhost:3000"}/joinworkshop/${code}`}>
                    <Button style={{ maxHeight: '80%' }}>Invite Link</Button>
                  </Link>
                </Col>
              </Row>
              {workshopData && workshopData.participants?.length > 0 ? (
                <UserProjectsDisplay colCount={3}>
                  {workshopData.participants.map((item) => (
                    <Item key={item.id} id={item.id} title={item.username} />
                  ))
                  }
                </UserProjectsDisplay>
              ) : (
                <p>
                  No Participants found. <Link to={`${process.env.REACT_APP_PUBLIC_URL ?? "http://localhost:3000"}/joinworkshop/${code}`}>Click Here</Link> to Sign Up.
                </p>
              )}
            </Col>
          </Row>
        </Tab>
        {user_data && workshopData && user_data.id == workshopData.workshopOwner.id ? <Tab eventKey="WorkshopOwner" title="Owner">
            <Row>
            <Col className="defaultPage" style={{ padding: "2%" }}>
              <Row>
                <Col>
                  <h1>Owner</h1>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'right' }}>
                  <Link to={`${process.env.REACT_APP_PUBLIC_URL ?? "http://localhost:3000"}/joinworkshop/${code}`}>
                    <Button style={{ maxHeight: '80%' }}>Invite Link</Button>
                  </Link>
                </Col>
              </Row>
              {workshopData && workshopData.workshopOwner ? (
                <UserProjectsDisplay colCount={3}>
                  {[workshopData.workshopOwner].map((item) => (
                    <Item key={item.id} id={item.id} title={item.username} />
                  ))
                  }
                </UserProjectsDisplay>
              ) : (
                <h4>
                  No User Owns This Workshop.
                </h4>
              )}
            </Col>
          </Row>
        </Tab> : null}
        {(user_data && workshopData?.instructors?.length > 0) && (workshopData.instructors.filter(i => i.id == user_data.id).length > 0 || user_data.id == workshopData.workshopOwner.id) ?
         <Tab eventKey="Instuctors" title="Instuctors">
          <Row>
            <Col className="defaultPage" style={{ padding: "2%" }}>
              <Row>
                <Col>
                  <h1>Instuctors</h1>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'right' }}>
                  <Link to={`${process.env.REACT_APP_PUBLIC_URL ?? "http://localhost:3000"}/joinworkshop/${code}`}>
                    <Button style={{ maxHeight: '80%' }}>Invite Link</Button>
                  </Link>
                </Col>
              </Row>
              {workshopData && workshopData.instructors?.length > 0 ? (
                <UserProjectsDisplay colCount={3}>
                  {workshopData.instructors.map((item) => (
                    <Item key={item.id} id={item.id} title={item.username} />
                  ))
                  }
                </UserProjectsDisplay>
              ) : (
                <h4>
                  No instructors found.
                </h4>
              )}
            </Col>
          </Row>
        </Tab> : null}
      </Tabs>
    </>
  );
}