import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery, useMutation, gql } from "@apollo/client";
import { Button, ListGroup, Row, Col, Modal, Form, Alert } from "react-bootstrap";
import { Cookies } from "react-cookie";
import { headerWithToken } from "../Components/Helpers/utils";

const GET_SURVEYS_BY_WORKSHOP_ID = gql`
  query GetSurveysByWorkshopId($workshopId: String!) {
    getSurveysByWorkshopId(workshopId: $workshopId) {
      id
      title
      questions {
        id
        text
      }
      instances{
        id
        title
      }
    }
  }
`;

const CREATE_SURVEY = gql`
  mutation CreateSurvey($input: CreateSurveyInput!) {
    createSurvey(input: $input) {
      id
      title
    }
  }
`;

const DELETE_SURVEY = gql`
  mutation DeleteSurvey($id: String!) {
    deleteSurvey(id: $id) {
      id
    }
  }
`;

const CREATE_SURVEY_QUESTION = gql`
  mutation CreateSurveyQuestion($input: CreateSurveyQuestionInput!) {
    createSurveyQuestion(input: $input) {
      id
      text
    }
  }
`;

const DELETE_SURVEY_QUESTION = gql`
  mutation DeleteSurveyQuestion($id: String!) {
    deleteSurveyQuestion(id: $id) {
      id
    }
  }
`;

const EDIT_SURVEY_QUESTION = gql`
  mutation EditSurveyQuestion($id: String!, $input: EditSurveyQuestionInput!) {
    editSurveyQuestion(id: $id, input: $input) {
      id
      text
      createdAt
    }
  }
`;

const CREATE_SURVEY_INSTANCE = gql`
  mutation CreateSurveyInstance($input: CreateSurveyInstanceInput!) {
    createSurveyInstance(input: $input) {
      id
      title
    }
  }
`;

const GET_SURVEY_RESPONSES = gql`
  query GetSurveyResponsesByQuestionIdAndInstanceId($questionId: String!, $instanceId: String!) {
    getSurveyResponsesByQuestionIdAndInstanceId(questionId: $questionId, instanceId: $instanceId) {
      answer
    }
  }
`;

const SurveyPage = () => {
  const cookies = new Cookies();
  const accessToken = cookies.get("accessToken");
  const [workshopId, setWorkshopId] = useState(null);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [newSurveyTitle, setNewSurveyTitle] = useState("");
  const [newSurveyQuestions, setNewSurveyQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [invokeTimes, setInvokeTimes] = useState({ pre: false, post: false });
  const [isEditing, setIsEditing] = useState(false); // New state for edit mode
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("workshopId");
    setWorkshopId(id);
    console.log("Workshop ID set to:", id);
  }, [location.search]);

  const { loading, error, data, refetch } = useQuery(GET_SURVEYS_BY_WORKSHOP_ID, {
    variables: { workshopId },
    context: headerWithToken(accessToken),
    skip: !workshopId,
  });

  const [createSurvey] = useMutation(CREATE_SURVEY, {
    context: headerWithToken(accessToken),
    onCompleted: async (response) => {
      const surveyId = response.createSurvey.id;
      console.log("Survey created with ID:", surveyId);

      // Create questions after survey is created
      for (const question of newSurveyQuestions) {
        await createSurveyQuestion({ variables: { input: { surveyId, text: question } } });
      }

      // Create survey instances based on selected invoke times
      const instances = [];
      if (invokeTimes.pre) {
        instances.push({ surveyId, title: 'Pre Survey', title: 'pre' });
      }
      if (invokeTimes.post) {
        instances.push({ surveyId, title: 'Post Survey', title: 'post' });
      }

      for (const instance of instances) {
        await createSurveyInstance({ variables: { input: instance } });
      }

      refetch();
      setNewSurveyTitle("");
      setNewSurveyQuestions([]);
      setInvokeTimes({ pre: false, post: false });
      setShowModal(false);
    },
    onError: (error) => {
      console.error("Error creating survey:", error);
    },
  });

  const [deleteSurvey] = useMutation(DELETE_SURVEY, {
    context: headerWithToken(accessToken),
    onCompleted: () => {
      refetch();
      setSelectedSurvey(null);
    },
    onError: (error) => {
      console.error("Error deleting survey:", error);
    },
  });

  const [createSurveyQuestion] = useMutation(CREATE_SURVEY_QUESTION, {
    context: headerWithToken(accessToken),
    onCompleted: () => refetch(),
    onError: (error) => {
      console.error("Error creating question:", error);
    },
  });

  const [deleteSurveyQuestion] = useMutation(DELETE_SURVEY_QUESTION, {
    context: headerWithToken(accessToken),
    onCompleted: () => refetch(),
    onError: (error) => {
      console.error("Error deleting question:", error);
    },
  });

  const [editSurveyQuestion] = useMutation(EDIT_SURVEY_QUESTION, {
    context: headerWithToken(accessToken),
    onCompleted: () => refetch(),
    onError: (error) => {
      console.error("Error updating question:", error);
    },
  });

  const [createSurveyInstance] = useMutation(CREATE_SURVEY_INSTANCE, {
    context: headerWithToken(accessToken),
    onCompleted: () => refetch(),
    onError: (error) => {
      console.error("Error creating survey instance:", error);
    },
  });

  const handleSurveyClick = (surveyId) => {
    setIsEditing(false);
    setSelectedSurvey(surveyId);
  };

  const handleCreateSurvey = () => {
    console.log("Creating survey with title:", newSurveyTitle);
    console.log("Workshop ID:", workshopId);
    createSurvey({ variables: { input: { workshopId, title: newSurveyTitle } } });
  };

  const handleAddNewSurveyQuestion = (e) => {
    e.preventDefault();
    if (newQuestion) {
      setNewSurveyQuestions([...newSurveyQuestions, newQuestion]);
      setNewQuestion("");
    }
  };

  const handleDeleteNewSurveyQuestion = (index) => {
    const updatedQuestions = [...newSurveyQuestions];
    updatedQuestions.splice(index, 1);
    setNewSurveyQuestions(updatedQuestions);
  };

  const handleAddQuestion = (e) => {
    e.preventDefault();
    if (currentQuestion) {
      createSurveyQuestion({ variables: { input: { surveyId: selectedSurvey, text: currentQuestion } } });
      setCurrentQuestion("");
    }
  };

  const handleDeleteQuestion = (questionId) => {
    deleteSurveyQuestion({ variables: { id: questionId } });
  };

  const handleDeleteSurvey = async (id) => {
    try {
      await deleteSurvey({ variables: { id } });
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditQuestion = async (id, text) => {
    try {
      await editSurveyQuestion({
        variables: {
          id,
          input: { surveyId: selectedSurvey, text },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const SurveyInstanceAverageResponses = ({ instanceId, questions }) => {
    return (
      <div>
        {questions.map((question, index) => (
          <AverageResponse
            key={question.id}
            questionId={question.id}
            instanceId={instanceId}
            questionNumber={index + 1}
          />
        ))}
      </div>
    );
  };
  
  const AverageResponse = ({ questionId, instanceId, questionNumber }) => {
    const { loading, error, data } = useQuery(GET_SURVEY_RESPONSES, {
      context: headerWithToken(accessToken),
      variables: { questionId, instanceId },
    });
  
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
  
    const responses = data?.getSurveyResponsesByQuestionIdAndInstanceId || [];
  
    if (responses.length === 0) {
      return <p>No responses available.</p>;
    }
  
    const total = responses.reduce((sum, response) => sum + parseFloat(response.answer), 0);
    const average = total / responses.length;
  
    return (
      <div>
        <p>Question {questionNumber}: {average.toFixed(2)}</p>
      </div>
    );
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <Alert variant="danger">Error: {error.message}</Alert>;

  return (
    <div style={{ position: 'relative', padding: '20px' }}>
      <div style={{ position: "absolute", top: "10px", right: "10px" }}>
        <Button variant="secondary" onClick={() => navigate(-1)}>
          Back
        </Button>
      </div>
      <Row>
        <Col sm={3}>
          <div style={{ padding: "20px" }}>
            <h3>Surveys</h3>
            <ListGroup>
              {data?.getSurveysByWorkshopId.map((survey) => (
                <ListGroup.Item
                  key={survey.id}
                  action
                  onClick={() => handleSurveyClick(survey.id)}
                  active={selectedSurvey === survey.id}
                  style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                  {survey.title}
                </ListGroup.Item>
              ))}
            </ListGroup>
            <Button variant="primary" onClick={() => setShowModal(true)} style={{ marginTop: "10px" }}>
              Add Survey
            </Button>
          </div>
        </Col>
        <Col sm={9}>
          <div style={{ padding: "20px" }}>
            {selectedSurvey ? (
              <>
                <h1>{data?.getSurveysByWorkshopId.find((s) => s.id === selectedSurvey)?.title}</h1>
                <h3>Questions:</h3>
                <ListGroup>
                  {data?.getSurveysByWorkshopId
                    .find((s) => s.id === selectedSurvey)
                    ?.questions.map((question) => (
                      <ListGroup.Item key={question.id} style={{ display: "flex", alignItems: "center" }}>
                        <Form.Control
                          type="text"
                          defaultValue={question.text}
                          onBlur={(e) => isEditing && handleEditQuestion(question.id, e.target.value)}
                          style={{ flexGrow: 1, marginRight: "10px" }}
                          readOnly={!isEditing}
                        />
                        {isEditing && (
                          <Button variant="danger" size="sm" onClick={() => handleDeleteQuestion(question.id)}>
                            Delete
                          </Button>
                        )}
                      </ListGroup.Item>
                    ))}
                </ListGroup>
                {isEditing && (
                  <Form.Group controlId="newQuestion">
                    <Form.Label>Add Question:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter question text"
                      value={currentQuestion}
                      onChange={(e) => setCurrentQuestion(e.target.value)}
                    />
                    <Button
                      variant="secondary"
                      onClick={handleAddQuestion}
                      style={{ marginTop: "10px" }}
                      disabled={!currentQuestion}
                    >
                      Add Question
                    </Button>
                  </Form.Group>
                )}
                <h3>Average Responses</h3>
                  <div
                    style={{
                      display: 'flex',
                      gap: '20px',
                      padding: '10px',
                    }}
                  >
                    <div style={{ flex: 3, display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                      {data.getSurveysByWorkshopId
                        .find((survey) => survey.id === selectedSurvey)
                        ?.instances.map((instance) => (
                          <div
                            key={instance.id}
                            style={{
                              flex: 1,
                              minWidth: '300px',
                              maxWidth: '45%',
                              boxSizing: 'border-box',
                              padding: '10px',
                            }}
                          >
                            <h4>{instance.title} Survey</h4>
                            <SurveyInstanceAverageResponses
                              instanceId={instance.id}
                              questions={
                                data.getSurveysByWorkshopId.find(
                                  (survey) => survey.id === selectedSurvey
                                ).questions
                              }
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                  {isEditing && (
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteSurvey(selectedSurvey)}
                      style={{ marginLeft: "10px" }}
                    >
                      Delete Survey
                    </Button>
                  )}
                <div style={{ marginTop: "20px" }}>
                  <Button
                    variant={isEditing ? "success" : "primary"}
                    onClick={() => setIsEditing(!isEditing)}
                    style={{ marginBottom: "10px" }}
                  >
                    {isEditing ? "Done" : "Edit"}
                  </Button>
                </div>
              </>
            ) : (
              <p>Select a survey</p>
            )}
          </div>
        </Col>
      </Row>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Survey</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="newSurveyTitle">
            <Form.Label>Survey Title:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter survey title"
              value={newSurveyTitle}
              onChange={(e) => setNewSurveyTitle(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="surveyInvokeTimes">
            <Form.Label>Select Invoke Times:</Form.Label>
            <Form.Check
              type="checkbox"
              label="Pre"
              checked={invokeTimes.pre}
              onChange={(e) => setInvokeTimes({ ...invokeTimes, pre: e.target.checked })}
            />
            <Form.Check
              type="checkbox"
              label="Post"
              checked={invokeTimes.post}
              onChange={(e) => setInvokeTimes({ ...invokeTimes, post: e.target.checked })}
            />
          </Form.Group>
          <Form.Group controlId="newSurveyQuestions">
            <Form.Label>Survey Questions:</Form.Label>
            {newSurveyQuestions.map((question, index) => (
              <div key={index} style={{ display: "flex", alignItems: "center" }}>
                <p style={{ flexGrow: 1 }}>{question}</p>
                <Button variant="danger" onClick={() => handleDeleteNewSurveyQuestion(index)}>
                  Delete
                </Button>
              </div>
            ))}
            <Form.Control
              type="text"
              placeholder="Enter question text"
              value={newQuestion}
              onChange={(e) => setNewQuestion(e.target.value)}
            />
            <Button
              variant="secondary"
              onClick={handleAddNewSurveyQuestion}
              style={{ marginTop: "10px" }}
              disabled={!newQuestion}
            >
              Add Question
            </Button>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleCreateSurvey}
            disabled={!newSurveyTitle}
          >
            Create Survey
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SurveyPage;
