import React from 'react';
import {gql, useMutation} from '@apollo/client';
import { headerWithToken } from '../Helpers/utils';
import { Button } from 'react-bootstrap';
const FRIENDREJECT = gql`mutation DeleteFriendRequest($input: FriendInput) {
    deleteFriendRequest(input: $input) {
      success
    }
  }`

const FriendReject = ({userId, friendId, accesstoken, renderObj}) =>{
    const [rejectFriend, {data, loading}] = useMutation(FRIENDREJECT)

    const doReject = (e) =>{
        rejectFriend({variables: {"input": {"acceptorId": userId, "requestorId": friendId}}, context: headerWithToken(accesstoken)})
        window.location.reload(false);
    }

    if(loading){
        return("Rejecting...")
    }
    if(data){
        const newRender = !renderObj.isRender
        renderObj.setRender(newRender)
    }
    return( 
        <Button className='rejectButton' onClick = {doReject}>
            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                <path class="checkmark__check" fill="none" d="M16 16 36 36 M36 16 16 36"/>
            </svg>
        </Button>
    );
}
export default FriendReject;