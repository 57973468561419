import { useEffect, useRef, useState } from "react"
import { freeModel } from "./FileUtils/GLBParser.js"

/* Temperary. Figure out how to use the public folder for this */
import { vert_source, frag_source } from './Shaders'

/* Definitions */
const TICK_RATE = 30 / 1000;
const IDENTITY_MATRIX = [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1];
const ZOOM_SENSITIVITY = 1/220;
const DRAG_SENSITIVITY = 1/500;


// TODO: Should move this into the renderer structure at some point, in case
// that is necessary for having multiple renderers on one page
/* Members */
var canvasRef;
var glRef;
const tickRate = 30 / 1000;
var running;

var program = {};
var models = [];

var camera = { pos: {x: 0, y: 0, z: 0}, pitch: {x: 0, y: 0} };
var single_model_transform = { pos: {x: 0, y: 0, z: 0}, pitch: {x: 0, y: 0, z: 0}};

/* Processing */
function tick() {
    /* Render */
    const gl = glRef.current;
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    // Pass camera information
    gl.uniform1fv(program.uniforms.view, new Float32Array([
        -camera.pos.x, -camera.pos.y, -camera.pos.z, -camera.pitch.x,
			-camera.pitch.y
    ]));
    gl.uniform1fv(program.uniforms.model, new Float32Array([
		single_model_transform.pos.x, single_model_transform.pos.y,
		single_model_transform.pos.z, single_model_transform.pitch.x,
		single_model_transform.pitch.y, single_model_transform.pitch.z
    ]));

    // Model rendering
	for (let model of models) {
		for (let node of model.nodes) {
			if (node.mesh == undefined)
				continue;

			const mesh = model.meshes[node.mesh];
			gl.uniformMatrix4fv(program.uniforms.mesh, false,
				node.matrix == undefined ? structuredClone(IDENTITY_MATRIX)
                    : node.matrix);
		
			for (let primitive of mesh.primitives) {
				// Bind the material
				// TODO look into roughness factor and metallic factor rendering
				if (primitive.material != undefined) {
					const material = model.materials[primitive.material]
							.pbrMetallicRoughness;
					if (material.baseColorTexture != undefined) {
						gl.uniform1i(program.uniforms.use_texture, 1);
						gl.bindTexture(gl.TEXTURE_2D,
							model.textures[material.baseColorTexture.index]);
					} else {
						gl.uniform1i(program.uniforms.use_texture, 0);
						gl.uniform4fv(program.uniforms.color,
							material.baseColorFactor != undefined
								? material.baseColorFactor : [.9, .9, .8, 1.0]);
					}
				} else {
					gl.uniform1i(program.uniforms.use_texture, 0);
					gl.uniform4f(program.uniforms.color, .9, .9, .8, 1.0);
				}

				// Bind attributes
				gl.enableVertexAttribArray(program.attribs.vertex);
				gl.enableVertexAttribArray(program.attribs.normal);
				gl.enableVertexAttribArray(program.attribs.texture_position);

				// TODO Cleanup
				let attrib = primitive.attributes.POSITION;
				gl.bindBuffer(gl.ARRAY_BUFFER, attrib.buffer);
				gl.vertexAttribPointer(program.attribs.vertex,
					attrib.componentsPerEntry, attrib.componentType, false, attrib.stride, 0);

				attrib = primitive.attributes.NORMAL;
				gl.bindBuffer(gl.ARRAY_BUFFER, attrib.buffer);
				gl.vertexAttribPointer(program.attribs.normal,
					attrib.componentsPerEntry, attrib.componentType, false, attrib.stride, 0);

				attrib = primitive.attributes.TEXCOORD_0;
				gl.bindBuffer(gl.ARRAY_BUFFER, attrib.buffer);
				gl.vertexAttribPointer(program.attribs.texture_position,
					attrib.componentsPerEntry, attrib.componentType, false, attrib.stride, 0);
				
				// TODO support both indexed and non-indexed drawing
				if (primitive.indexed)  {
					gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, primitive.indices.buffer);
					gl.drawElements(gl.TRIANGLES, primitive.vertices,
						primitive.indices.componentType, 0);
				} else {
					gl.drawArrays(gl.TRIANGLES, 0, primitive.vertices);
				}

				let err = gl.getError();
				if (err != 0) {
					console.error('ERROR', err);
					return;
				}
			}
        }
    }


    if (running)
        setTimeout(tick, TICK_RATE);
}

export function getGL() {
    if (glRef?.current) {
        return glRef;
    } else {
        alert("Calling Renderer:getGL() before context has been created");
        return null;
    }
}

export function getScreenshot() {
	tick();
	return canvasRef.current?.toDataURL("png");
}

export function setModels(newModels) {
    for (const model of models)
        freeModel(glRef.current, model)
    models = newModels;
}

/* Components and preperation. */
export async function useLoad(interaction) {
    const canvas = canvasRef.current;
    glRef.current = canvas.getContext("webgl");
    if (glRef.current == null) {
        alert("WebGL is unsupported on this browser but it is currently required.");
        return;
    }

    const gl = glRef.current;
    gl.clearColor(0., 0., 0., 0.);
	gl.clearDepth(1.);
	gl.enable(gl.BLEND);
	gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
	gl.enable(gl.DEPTH_TEST);
	// TODO grant control per material for culling faces
	gl.disable(gl.CULL_FACE);
	gl.depthFunc(gl.LEQUAL);
	gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, false);


	if (gl.getExtension('OES_element_index_uint') == null) {
		alert("uint not supported at the moment.");
	}


	// Shader Program
	// const vert_source = await (await fetch('public/shaders/plain.vert')).text();
	// const frag_source = await (await fetch('public/shaders/plain.frag')).text();

	const vert_shader = gl.createShader(gl.VERTEX_SHADER);
	gl.shaderSource(vert_shader, vert_source);
	gl.compileShader(vert_shader);
	const frag_shader = gl.createShader(gl.FRAGMENT_SHADER);
	gl.shaderSource(frag_shader, frag_source);
	gl.compileShader(frag_shader);

	program.hdl = gl.createProgram();
	gl.attachShader(program.hdl, vert_shader);
	gl.attachShader(program.hdl, frag_shader);
	gl.linkProgram(program.hdl);
	gl.deleteShader(vert_shader);
	gl.deleteShader(frag_shader);

	if (!gl.getProgramParameter(program.hdl, gl.LINK_STATUS)) {
		console.error('| Failed to link shader program |\n'
			+ gl.getProgramInfoLog(program.hdl));
		return;
	}
	
    gl.useProgram(program.hdl);
	program.attribs = {
		vertex: 0,
		normal: 1,
		texture_position: 2,
	};
	gl.bindAttribLocation(program.hdl, program.attribs.vertex, 'a_vertex');
	gl.bindAttribLocation(program.hdl, program.attribs.normal, 'a_normal');
	gl.bindAttribLocation(program.hdl, program.attribs.texture_position, 'a_texture_position');
	program.uniforms = {
		proj: gl.getUniformLocation(program.hdl, 'u_proj'),
		mesh: gl.getUniformLocation(program.hdl, 'u_mesh'),
		view: gl.getUniformLocation(program.hdl, 'u_view'),
		model: gl.getUniformLocation(program.hdl, 'u_model'),
		use_texture: gl.getUniformLocation(program.hdl, 'u_use_texture'),
		color: gl.getUniformLocation(program.hdl, 'u_color'),
	}

    // Global callbacks
    // TODO: have this trigger on resize
    const resizeCallback = _ => {
		canvas.width = canvas.offsetWidth;
		canvas.height = canvas.offsetHeight;
		glRef.current.canvas.width = canvas.width;
		glRef.current.canvas.height = canvas.height;
        gl.viewport(0, 0, canvas.width, canvas.height);

        gl.uniformMatrix4fv(program.uniforms.proj, false,
            [1., 0., 0., 0.,
             0., canvas.offsetWidth / canvas.offsetHeight, 0., 0.,
             0., 0., -100.01/99.99, -1,
             0., 0., -2/99.99, 0]);
    };
    resizeCallback();

    // Set callbacks based on interaction type
    if (interaction == "mouse") {
        canvas.addEventListener("wheel", e => {
			camera.pos.z += e.deltaY * ZOOM_SENSITIVITY;
        });
		let pressed = false;
		canvas.addEventListener("pointerdown", e => pressed = true);
		document.body.addEventListener("pointerup", e => pressed = false);
		let last_x, last_y;
		document.body.addEventListener("pointermove", e => {
			if (pressed && last_x) {
				single_model_transform.pitch.y += (e.screenX - last_x)
					* DRAG_SENSITIVITY;
				single_model_transform.pitch.x += (e.screenY - last_y)
					* DRAG_SENSITIVITY;
			}
			last_x = e.screenX;
			last_y = e.screenY;
		})
    } else if (interaction == "mkb") {
		// Future space for different control options
    }

    running = true;
}

export function useUnload() {
    running = false;

    for (const model of models)
        freeModel(glRef.current, model);
    models.length = 0;

	glRef.current.deleteProgram(program.hdl);
}

export default function({width="500px", height="500px", interaction="mouse",
		backgroundColor="lightgray"}) {
    canvasRef = useRef(0);
    glRef = useRef(0);
    
    // Init and deinit WebGL
    useEffect(_ => {
        useLoad(interaction).then(_ => setTimeout(tick));
        return useUnload;
    }, []);

    
    return (<canvas ref={canvasRef} style={{
        width: width, height: height, backgroundColor: backgroundColor
    }} />)
};