import React from "react";
import {Routes, Route} from "react-router-dom";
import LandingPage from "./Pages/LandingPage";
import Login from "./Pages/LoginPage";
import EmailVerification from "./Pages/EmailVerificationPage";
import ForgotPassword from "./Pages/ForgotPasswordPage";
import Discovery from "./Pages/DiscoveryPage";
import SettingPage from "./Pages/SettingPage";
import RoomPage from "./Pages/RoomPage";
import AvatarDetailPage from "./Pages/AvatarDetailPage";
import RoomDetailPage from "./Pages/RoomDetailPage";
import RegisterPage from "./Pages/RegisterPage"
import ResetPasswordPage from "./Pages/ResetPasswordPage";
import VerifyPage from "./Pages/VerifyPage";
import VerifySuccessPage from "./Pages/VerifySuccessPage";
import CreateProjectPage from "./Pages/CreateProjectPage";
import SearchPage from "./Pages/SearchPage";
import AccountPage from "./Pages/AccountPage";
import OTPCode from "./Pages/OTPCode";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {Footer} from "./Components/Footer"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import EmailVerified from "./Pages/EmailVerified";
import PasswordRecovery from "./Pages/PasswordRecovery";
import MyProjectPage from "./Pages/MyProjectPage";
import { withCookies } from 'react-cookie';
import EditProjectPage from "./Pages/EditProjectPage";
import WorkshopLogin from "./Pages/WorkshopLogin";
import CreateWorkshop from "./Pages/CreateWorkshop";
import ShowWorkshop from "./Pages/ShowWorkshopPage";
import SurveyPage from "./Pages/SurveyPage"
import MonitorWorkshop from "./Pages/MonitorWorkshop";
import ParticipantsList from "./Pages/ParticipantsList";

function App() {

  return (
    <Container fluid className="App">
      <Row style={{minHeight: '100%'}}>
        <Col style={{minHeight: '100%', margin:'0'}}>
              <Routes>
                    <Route path="/" element={<LandingPage/>}></Route>
                    <Route path="/emailverification" element={<EmailVerification/>}/>
                    <Route path="/emailverified" element={<EmailVerified/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/register" element={<RegisterPage/>}/>
                    <Route path="/forgotpassword" element={<ForgotPassword/>}/>
                    <Route path="/resetpassword" element={<ResetPasswordPage/>}/>
                    <Route path="/passwordrecovery" element={<PasswordRecovery/>}/>
                    <Route path="/discovery" element={<Discovery/>}/>
                    <Route path="/otpcode" element={<OTPCode/>}/>
                    <Route path="/roompage" element={<RoomPage/>}/>
                    <Route path="/settingpage" element={<SettingPage/>}/>
                    <Route path="/roomdetailpage" element={<RoomDetailPage/>}/>
                    <Route path="/avatardetailpage" element={<AvatarDetailPage/>}/>
                    <Route path="/verify" element={<VerifyPage/>}/>
                    <Route path="/verifysuccess" element={<VerifySuccessPage/>}/>
                    <Route path="/myprojects" element={<MyProjectPage/>}/>
                    <Route path="/createproject" element={<CreateProjectPage/>}/>
                    <Route path="/search" element = {<SearchPage/>}/>
                    <Route path="/account" element = {<AccountPage/>}/>
                    <Route path="/editproject" element = {<EditProjectPage/>}/>
                    <Route path="/showWorkshop" element = {<ShowWorkshop/>}></Route>
                    <Route path="/joinWorkshop/:invitationCode?" element = {<WorkshopLogin/>}></Route>
                    <Route path="/createWorkshop" element = {<CreateWorkshop/>}></Route>
                    <Route path="/surveys" element = {<SurveyPage/>}></Route>
                    <Route path="/monitorWorkshop" element = {<MonitorWorkshop/>}></Route>
                    <Route path="/participantList" element = {<ParticipantsList/>}></Route>
              </Routes>
         <Footer/>
        </Col>
  </Row>
    </Container>
  );
}

export default withCookies(App);
