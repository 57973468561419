import { useNavigate } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RfNavbar from '../Components/Navbar'
import Button from 'react-bootstrap/Button';
import RearrangeImages from "../Components/RearrangeImages";
import {gql, useMutation, useQuery} from '@apollo/client'
import {Cookies }from 'react-cookie';
import {headerWithToken, kickUser} from "../Components/Helpers/utils";
import Form from 'react-bootstrap/Form';
import { useState, useCallback} from "react";
import EditProjLoading from "../Components/EditProjectLoading";
import { sendCreateProjToFediVerse } from "./CreateProjectPage";

const categories = ['Education', 'Game', 'Historical', 'Science'];
export default function EditProjectPage() {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const accesstoken = cookies.get("accessToken")
    const queryParams = new URLSearchParams(window.location.search);
    const projectID = queryParams.get("p");
    const user_data = cookies.get("userData")

    const [categoryState, setCategoryState] = useState([])
    const [galleryState, setGalleryState] = useState([])
    const [checkedState, setCheckedState] = useState(
        new Array(categories.length).fill(false)
      );

    const [publicState, setPublicState] = useState(false);

      const [popFlag, setPopFlag] = useState(true);
      const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log(name + " " + value)
        setValues({...values, [name]: value });
        
    }

    const [values, setValues] = useState({"projectName": "", "description": ""});
    const GETPROJECTDATA = gql`query GetProjectById($getProjectByIdId: String) {
        getProjectById(id: $getProjectByIdId) {
          description
          categories
          details
          projectName
          isPublic
          gallery
          publicUrl
          globalId
          projectOwner {
            id
          }
          projectCoOwners {
            id
          }
        }
      }`    

        
    const EDITPROJECT = gql`mutation EditProject($input: EditProjectInput) {
        editProject(input: $input) {
          id
        }
      }`
      
    const [editProject, editData] = useMutation(EDITPROJECT)

    const sendEditProjToFediVerse = async (editProject) => {
          try {
            const toMyOutbox = {
              "@context": ["https://www.w3.org/ns/activitystreams", {accessToken: accesstoken}],
              "type": "Update",
              "actor": {...user_data, url: `${process.env.REACT_APP_API}/~${user_data.username}`, type: 'Person'},
              "name": "Edit Project on Fediverse",
              "object": editProject,
            };
  
            const myServer = (process.env.REACT_APP_API ?? "http://localhost:4000") + '/outbox';
            
            const toMyOutboxRequest = await fetch(myServer, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(toMyOutbox),
            });
            if(toMyOutboxRequest.status === 201) {
             console.log("Project sent to Fediverse")
            }
        
          } catch (e) {
            console.log(e);
          }
      }
      
    const handleImageChange = useCallback((data) =>{
        var newGallery = [];
        for(var i = 0; i < data.length ; i++){
            newGallery.push(data[i].imageName)
        }
        setGalleryState(newGallery);
    }, [])
      const {data, loading, error} = useQuery(GETPROJECTDATA, {variables:{"getProjectByIdId": projectID }, context: headerWithToken(accesstoken)})
      if(error) {
        if(error.message === 'Not Authenticated'){
            kickUser(navigate, cookies);
        }
    }
      if(loading){
        return (<EditProjLoading/>)
      }
     
      const project_data = data.getProjectById;

      //Handles changing categories
      const handleCategoryChange = (position) =>{
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);
        var arr = []
        for(var i=0;i<updatedCheckedState.length;i++){
            if(updatedCheckedState[i])  arr.push(categories[i])
        }
        
        setCategoryState(arr)
    }
    //populates categorystate and checkedstate with project data
    if(data && popFlag){ 
        values.projectName = project_data.projectName;
        values.description = project_data.description;
        values.details = project_data.details;
        setPublicState(project_data.isPublic);
        setGalleryState(project_data.gallery);
        for(var i = 0; i < categories.length; i++){
            if(project_data.categories.includes(categories[i])){ 
                checkedState[i] = true;
                if(!categoryState.includes(categories[i]))  categoryState.push(categories[i])
            }
        }
        setPopFlag(false);
    }
    
    //Checks project ownership and coownership
    const checkOwn = () =>{
    if(user_data.id === project_data.projectOwner.id) return true;
        for(var i = 0; i < project_data.projectCoOwners.length ; i++){
            if(user_data.id === project_data.projectCoOwners[i].id) return true;
        }
        
        return false;
    }   

    //checks if user owns the project, doesn't currently check for co-owners
    if(!checkOwn()){
        //if they're not the owner, send them back to homepage
        navigate("/homepage")
    }

    //sets default values for checkboxes
    const getIfChecked = (index) =>{
        if(project_data.categories.includes(categories[index])){ 
            return true;
        }
        return false;
    }
    
    const onSubmit = (e) =>{
      e.preventDefault()

      const editInput =  {
          "input": {
            "projectId": projectID,
            "projectName": values.projectName,
            "description": values.description,
            "categories": categoryState,
            "details" : values.details,
            "isPublic" : publicState,
            "gallery" : galleryState,
            "globalId": project_data.globalId ?? "",
            "publicUrl": project_data.publicUrl ?? ""
          }
      }

      if(publicState && !project_data.publicUrl){
        
        const newProject = {
          input: {
            projectName: values.projectName,
            projectOwnerId: user_data.id,
            description: values.description,
            details: values.details,
            categories: categoryState,
            isPublic: publicState,
            gallery: galleryState,
            publicUrl: `${process.env.REACT_APP_PUBLIC_URL}/~${user_data.username}`,
            globalId: editInput.input.globalId ?? `${process.env.REACT_APP_PUBLIC_URL}~${user_data.id}~${new Date().getTime()}`,
          },
        };

        editInput.input.globalId = newProject.input.globalId;
        editInput.input.publicUrl = newProject.input.publicUrl;
       
        sendCreateProjToFediVerse(newProject, accesstoken, user_data)
      }
      else{
        sendEditProjToFediVerse(editInput)
      }
      
      editProject({variables:editInput, context: headerWithToken(accesstoken)})
  }
  if(editData.data){
      navigate(`../roomdetailpage?p=${projectID}`)
  }

    return (
        <Row className = "defaultPage ">
            <RfNavbar></RfNavbar>
            <Form onSubmit = {onSubmit}>
                <Col>
                    <Row style = {{paddingTop: "50px"}}>
                        <Col style={{maxWidth:'1%'}}></Col>
                        <Col style={{ minWidth:'60%'}}>
                        <h4 style={{paddingBottom:'0%', position:'relative', marginBottom:'2%'}}>Images: <svg className="editVector"></svg></h4>
                            {(data) ? (<RearrangeImages images = {project_data.gallery} onImageMove={handleImageChange}></RearrangeImages>) : null}
                        </Col>
                        <Col style={{paddingLeft:'5%', paddingRight:'5%'}}>
                            <h4 style={{paddingBottom:'0%', position:'relative', marginBottom:'2%'}}>Title: <svg className="editVector"></svg></h4>
                            
                            <Form.Group controlId="projectTitle" as={Row} style={{paddingBottom:'5%'}}><Form.Control required size="lg" type="text" name="projectName" defaultValue={project_data.projectName} onChange={onChange} style={{marginBottom:'2%'}} className='editText'></Form.Control></Form.Group>
                            <h4 style={{paddingBottom:'0%', position:'relative', marginBottom:'2%'}}>Description: <svg className="editVector"></svg></h4>
                            <Form.Group controlId="projectDescription" as={Row} style={{paddingBottom:'5%'}} ><Form.Control required size="md"  maxLength="256" as="textarea" type="text" name="description" rows={5} className='editText' defaultValue={project_data.description} onChange={onChange}></Form.Control></Form.Group>
                            <h4 style={{paddingBottom:'0%', position:'relative', marginBottom:'2%'}}>Details: <svg className="editVector"></svg></h4>
                            <Form.Group controlId="projectDetails" as={Row} style={{paddingBottom:'5%'}}>
                                        <Form.Control required size="sm" as="textarea" type="text" rows={12} className='editText' name = 'details' defaultValue={project_data.details} onChange = {onChange}></Form.Control>
                            </Form.Group>
                            <h4 style={{paddingBottom:'0%', position:'relative', marginBottom:'2%'}}>Categories: <svg className="editVector"></svg></h4>
                            {categories.map((category, index) => (
                                            <div key={`check-${category}`} style ={{ marginLeft: "15px"}}>
                                    <Form.Check 
                                        type={"checkbox"}
                                        id={`${category}-check`}
                                        label={category}
                                        defaultChecked={getIfChecked(index)}
                                        onChange = {() => handleCategoryChange(index)}
                                    />
                                    </div>
                                        ))}
                            <Form.Check 
                                        type={"checkbox"}
                                        id={`public-check`}
                                        name={'public'}
                                        label={"Public?"}
                                        defaultChecked={project_data.isPublic}
                                        onChange = {() => setPublicState(!publicState)}
                                    />

                            <Button className = "defaultButton" variant="primary" type="submit" style={{marginTop:'10%', marginBottom:'2%'}}>
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Form>
        </Row>
    )
}