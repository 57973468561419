import React from 'react';
import {gql, useQuery} from '@apollo/client';
import { headerWithToken } from '../Helpers/utils';
import { Button } from 'react-bootstrap';
import SendFriendRequest from './FriendSendRequest';
import RemoveFriend from './FriendRemove';
const GETFRIENDS = gql`query GetFriendManage($userId: String, $getFriendRequestUserId2: String!) {
    getFriendManage(userId: $userId) {
      friends {
        id
      }
    }
    getFriendRequest(userId: $getFriendRequestUserId2) {
      from {
        id
      }
    }
  }`

const FriendAddButton = ({userId, friendId, accesstoken}) =>{
    const {data, loading, error} = useQuery(GETFRIENDS, {variables: {"userId": userId, "getFriendRequestUserId2": friendId}, context: headerWithToken(accesstoken)});
    

    if(loading){
        return null;
    }
    if(error){
      return null;
    }

    if(userId === friendId){
        return null;
    }
    else if(data.getFriendManage.friends.some(friend => friend.id === friendId)){
        return (
            <RemoveFriend userId = {userId} friendId = {friendId} accesstoken = {accesstoken}/>
        )
    }
    else if(data.getFriendRequest.some(request => request.from.id === userId)){
        return(<Button disabled>Friend Request Sent!</Button>)
    }
    else{
        return (
            <SendFriendRequest userId = {userId} friendId = {friendId} accesstoken = {accesstoken} />
        ) 
    }
    
}
export default FriendAddButton;