import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import RfNavbar from '../Components/Navbar'
import UserProjectsDisplay from '../Components/UserProjectsDisplay';
import { gql, useQuery } from '@apollo/client';
import {Cookies }from 'react-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import {headerWithToken, kickUser} from '../Components/Helpers/utils';
import ProfilePicture from '../Components/ProfilePicture';
import { useEffect } from 'react';



export default function SearchPage() {
    const navigate = useNavigate()
    const cookies = new Cookies();
    const accesstoken = cookies.get("accessToken")
    const queryParams = new URLSearchParams(window.location.search);
    const searchTerm = queryParams.get("s");
    useEffect(()=>{
      if(accesstoken == null){
        console.log("NO ACCESSTOKEN")
        navigate('/')
    }
    })
    const GETSEARCH = gql`query Search($searchText: String!) {
        search(searchText: $searchText) {
          projects {
            projectName
            id
          }
          people {
            username
            id
            profileImg
          }
        }
      }`;

      const {data, error} = useQuery(GETSEARCH, {
        variables: {"searchText": searchTerm},
        context: headerWithToken(accesstoken)
      })
      if(error) {
        if(error.message === 'Not Authenticated'){
            kickUser(navigate, cookies);
        }
    }
    const projectsList = (data) ? data.search.projects : [];
    const usersList = (data) ? data.search.people : [];
    //console.log(projectList);

    const Item = props => {
        //destrcture the props
        const { title , id} = props
    
        return (
        <Link to={{ 
                pathname: "../roomdetailpage",
                search: `?p=${id}`
                }}>
          <div>
            <h3>{title}</h3>
          </div>
          </Link>
        )
      }

    return (
      <>
      <RfNavbar></RfNavbar>
        <Row className='defaultPage'>
            <Col style={{padding:'5%'}}>
                <Row style={{marginBottom: '10%'}}>
                    <Col><h1>Searching for... {searchTerm}</h1></Col>
                </Row>
                <Row style= {{textAlign: "left"}}> 
                  <h3>Projects</h3>
                </Row>
                {(data) ? (
                <UserProjectsDisplay colCount={3} md={4} style={{height:'30vh', overflowY:"scroll"}}>
                    { projectsList.length > 0 ? projectsList.map((item) => <Item key={item.id} id={item.id} title={item.projectName}/>) : [<p style={{height:"20vh"}}>No projects are found. <Link to="/createproject" >Click Here</Link> to create one</p> ] }
                </UserProjectsDisplay>):null}
                <Row style= {{textAlign: "left"}}> 
                  <h3>Users</h3>
                </Row>
                <Row>
                <ListGroup style={{height:'50vh', overflowY:"scroll"}}>
                <UserProjectsDisplay colCount={3} >
                {usersList.map((friend) => (
                  <Link to={`../account?acc=${friend.id}`} style={{textDecoration:'none'}}>
                    <ListGroupItem style = {{backgroundColor: 'grey', borderRadius:'10px'}} className="my-1 my-lg-3">
                        <Row className='ms-1'>
                          <Col className='col-auto col-lg-3' >
                            <ProfilePicture profileImg = {friend.profileImg} useClass = 'smallProfilePicSettings'/>   
                            </Col>
                        <Col className="col-auto col-lg-9" >
                            <div className="my-auto my-lg-2" style={{textAlign:"left", color:"white"}}>{friend.username}</div>   
                        </Col>
                        </Row>
                    </ListGroupItem>
                    </Link>
                 ))}
                 </UserProjectsDisplay>
            </ListGroup>
                </Row>
            </Col>
        </Row>
        </>
    )
}