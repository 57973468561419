
import {Row,Button, Col} from 'react-bootstrap';
import {gql, useMutation} from '@apollo/client'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const VERIFY = gql`mutation Verification($input: VerificationInput) {
    verification(input: $input) {
      verifiedUser
    }
  }`
  
export default function VerifySuccessPage() {
    const navigate = useNavigate();
    const [verify, { error, data}] = useMutation(VERIFY)
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    const email = queryParams.get("email")
    
    useEffect(() => {
        verifySuccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

      const verifySuccess = () =>{
        verify({variables: {"input": {"token" : token}}});
      }

      const sendVerify = () =>{
        localStorage.setItem("user_email", email);
        navigate("../verify");
      }
      console.log(token)
    return (
        <>
        <Row className='defaultPage SignLoginMainRow'>
            <Col style={{display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:"rgba(17, 22, 35, 0)"}}>
                <Row>
                    <Col style={{backgroundColor:"rgba(9,9,9,0.6)",padding:'50px', borderRadius:'25px', color: "white", maxWidth: "500px"}}>
                        
                        <Row>
                        <Row><Col><h1><b>Welcome to RealityFlow</b></h1></Col></Row>
                        <br></br>
                        <Row><Col><h4>Account Verification</h4></Col></Row>
                        <br></br>
                        <Row><Col>{data && <div>Account has been successfully verified</div>}</Col></Row>
                        <Row><Col>{error && <div>Link has expired, <Button href="#" onClick = {sendVerify}>Click here to resend verification link</Button></div>}</Col></Row>
                        <Row><Col><a href = "../login">Click here to return to login</a></Col></Row>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
        </>
    )
}