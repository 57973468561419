import { useEffect, useRef } from "react";
import img from "../Images/favicon.ico";

export default function ({style={}}) {
    const imgRef = useRef(null);
    const intervalRef = useRef(null);
    const degreeSpeedRef = useRef(0.5);  // Use a ref to hold degree speed
    const maxSpeed = 10;  // Maximum speed
    const minSpeed = 0.1;  // Minimum speed
    const speedIncrement = 0.10;  // Increment for changing speed
    let increasingSpeed = true;  // Boolean to track direction of speed change

    let degrees = 0;

    const useSpin = () => {
        if (imgRef.current) {
            imgRef.current.style.transform = `rotate(${degrees}deg)`;
            degrees += degreeSpeedRef.current;

            // Update the speed
            if (increasingSpeed) {
                degreeSpeedRef.current += speedIncrement;
                if (degreeSpeedRef.current >= maxSpeed) {
                    increasingSpeed = false;
                }
            } else {
                degreeSpeedRef.current -= speedIncrement;
                if (degreeSpeedRef.current <= minSpeed) {
                    increasingSpeed = true;
                }
            }
        }
    };

    useEffect(() => {
        intervalRef.current = setInterval(useSpin, 16); // Roughly 60 FPS

        return () => {
            clearInterval(intervalRef.current);
        };
    }, []);

    if (!style.width)
        style.width = "50px";
    if (!style.height)
        style.height = "50px";

    return (
        <img src={img} ref={imgRef} style={style} />
    );
}