
import {Row, Col, Button, Form} from 'react-bootstrap';
import {gql, useMutation} from '@apollo/client';
import { useState } from "react";
export default function ForgotPasswordPage() {
    const FORGOTREQUEST = gql`mutation ResetPassRequest($email: String!) {
        resetPassRequest(email: $email) {
          message
          success
        }
      }`
        //i forgor
      const [forgor, {data, error}] = useMutation(FORGOTREQUEST)
      const [values, setValues] = useState({});

      const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setValues({...values, [name]: value });
    }

    const onSubmit = (e) =>{
        e.preventDefault();
        forgor({variables: {"email": values.email}})
    }
    return (
        <Row className='defaultPage SignLoginMainRow'  onSubmit= {onSubmit}>
            <Col style={{display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:"rgba(17, 22, 35, 0)"}}>
                <Row>
                    <Col style={{backgroundColor:"rgba(9,9,9,0.6)",padding:'50px', borderRadius:'25px', color: "white", maxWidth: "500px"}}>
                        
                        <Row>
                        <Row><Col><h1><b>Welcome to RealityFlow</b></h1></Col></Row>
                        <br></br>
                        <Row><Col><h4>Forgot your password?</h4></Col></Row>
                            <Col style={{justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
                                <Form >
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Enter your email</Form.Label>
                                        <Form.Control type="email" name = "email" placeholder="Enter email" onChange = {onChange}/>
                                        <Form.Text className="text-muted">
                                            {error && <div style ={{color:"red"}}>{error.message}</div>}
                                            
                                        </Form.Text>
                                    </Form.Group>
                                    <br></br>
                                    {data && <div>{data.resetPassRequest.message}</div>}
                                    <Button className = "defaultButton" variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </Form>
                                <br></br>
                                
                            </Col>
                            <Row><Col><a href = "../login">Click here to return to login</a></Col></Row>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
