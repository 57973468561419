import { useNavigate } from "react-router-dom";
import {Row, Col, Button, Form} from 'react-bootstrap';

import { useState, useEffect } from "react";
import {gql, useMutation} from '@apollo/client';
import jwtDecode from 'jwt-decode';
import { useCookies} from 'react-cookie';


const LOGIN = gql` mutation Login($input: LoginInput) {
    login(input: $input) {
      accessToken
    }
  }
  `;


export default function LoginPage() {
    const navigate = useNavigate();
    
    
    const [values, setValues] = useState({});
    // const [errorMessage, setErrorMessage] = useState("");
    const [login, {data, error}] = useMutation(LOGIN);
    const [, setCookie] = useCookies(['accessToken', 'userData'])
    /*const [login, data] = useMutation(LOGIN);*/
    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setValues({...values, [name]: value });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if(!values.password) return;
        login({variables: {"input": {"email": values.email, "password" : values.password}}})
    } 
    
    useEffect(() => {
        if(data != null){
        //if data exists, the login must've been successful so we can send them to the main page and store their accessToken as a cookie
        const accessToken = data.login.accessToken;
        const decodedToken = jwtDecode(accessToken)
        setCookie('accessToken', accessToken, {path: '/'})
        setCookie('userData', decodedToken, {path: '/'})
        navigate('/')
    }});

    if(error){
        //If the user's email is unverified, store the email and navigate them to the verification page
        if(error.graphQLErrors[0].extensions.code === "UNVERIFIED_EMAIL"){
            localStorage.setItem("user_email", values.email)
            navigate("../verify")
        }
        else {
            console.log(JSON.stringify(error));
        }
    }

    return (
        <Row className='defaultPage SignLoginMainRow'>
            <Col style={{display:'flex', justifyContent:'center', alignItems:'center', width:'50%', backgroundColor:"rgba(17, 22, 35, 0)"}}>
                <Row>
                    <Col style={{backgroundColor:"rgba(9,9,9,0.6)",padding:'50px', borderRadius:'25px', color: "white"}}>
                        
                        <Row>
                        <Row><Col><h1><b>Welcome to RealityFlow</b></h1></Col></Row>
                        
                        <br></br>
                            <Col>
                                <Form onSubmit = {onSubmit}>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Email address or username</Form.Label>
                                        <Form.Control name = "email" placeholder="Enter email or username" onChange = {onChange}/>
                                        <Form.Text className="text-muted">
                                        We'll never share your email with anyone else.
                                        </Form.Text>
                                    </Form.Group>
                                    <br></br>

                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" name = "password" placeholder="Password" onChange = {onChange}/>
                                    </Form.Group>
                                    {error && <div className = 'error'>{error.message}</div>}
                                    <div style={{display: 'flex' , justifyContent:'left', alignItems: 'left', paddingTop: '7px'}} className="mb-3">
                                   {/*<Form.Check 
                                        type="checkbox"
                                        id="rememberpassCheck"
                                        label="Remember Password?"
    />*/}
                                    </div>
                                    <br></br>
                                    <Button className = "defaultButton" variant="primary" type="submit" >
                                        Sign In
                                    </Button>
                                </Form>
                                <br></br>
                                <Row><Col><a style={{fontSize:'16px'}} href="../forgotpassword">Forgot Password?</a></Col></Row>
                                <Row><Col><a style={{fontSize:'16px'}} href="../register">Don't have an account? Sign up</a></Col></Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
