import { useNavigate } from "react-router-dom";
import {Row, Col, Button, Form} from 'react-bootstrap';
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';

const CREATEPARTICIPANT = gql`mutation signUpWorkshop($input: createParticipantInput) {
    createParticipant(input: $input) {
        username
    }
}`

const WORKSHOPLOGIN = gql` query Login($input: getParticipantInput) {
    getParticipantsByWorkshopId(input: $input) {
        username
        role
        selectedHeadset
        projects {
            id
            projectName
            description
            categories
            details
            projectName
            isPublic
            gallery
            publicUrl
            globalId
        }
    }
  }
  `;

const GETWORKSHOPBYCODE = gql`query GetWorkshop($code: String!) {
    getWorkshopByCode(code: $code) {
        name
        id  
        description
        participantAmount
        workshopOwner{
            username
        }
        participants {
            username
            userType
        }
    }
}`

function getExcludedNumbers(arr = [], n = 0) {
    const rangeArray = Array.from({ length: n }, (_, i) => i + 1);
    
    const excludedNumbers = rangeArray.filter(num => !arr.includes(num));
    
    return excludedNumbers;
}

export default function WorkshopLogin() {
    const navigate = useNavigate();
    const { invitationCode } = useParams();

    const [ifSignup, setIfSignup] = useState(true)
    const [workshopData, setWorkshopData] = useState(null)
    const [workshopInput, setWorkshopInput] = useState({
        headsetNumber: "1",
    });
    const [createParticipantReq] = useMutation(CREATEPARTICIPANT);
    const [loginWorkshopReq, {lwrLoading, lwrError, lwrData}] = useLazyQuery(WORKSHOPLOGIN);
    const {data, loading} = useQuery(GETWORKSHOPBYCODE, {variables: {code: invitationCode}});
    const [availableHeadsets, setAvailableHeadsets] = useState([])
    
    useEffect(() => {
        if(data){
            setWorkshopData(data.getWorkshopByCode)
            setAvailableHeadsets(getExcludedNumbers(data.getWorkshopByCode?.participants.map(i => i.selectedHeadset), data.getWorkshopByCode?.participantAmount))
        }
    }, [data, loading])
   
    const onChange = (e, key) => {
        setWorkshopInput(prevState => ({
            ...prevState,
            [key]: e.target.value
          }));
    }
    
    const signInWorkshop = async() => {
        const input = {
            username: workshopInput.username, 
            workshopId: workshopData.id,
        }

        loginWorkshopReq({variables: {"input": input}})
    }
    if(lwrData?.getParticipantsByWorkshopId)
        alert('You have successfully logged in for the workshop')
    const signUpWorkshop = async () => {
        const input = {
            workshopId: workshopData.id,
            selectedHeadset: parseInt(workshopInput.headsetNumber)
        }       

        try {
            await createParticipantReq({variables: {"input": input}});
            alert('You have successfully signed up for the workshop')
        } catch (err) {
            alert(err.message);
        }
    }
    return (
        <Row className='defaultPage SignLoginMainRow'>
            <Col style={{display:'flex', justifyContent:'center', alignItems:'center', width:'50%', backgroundColor:"rgba(17, 22, 35, 0)"}}>
                <Row>
                    <Col style={{backgroundColor:"rgba(9,9,9,0.6)",padding:'50px', borderRadius:'25px', color: "white"}}>
                        
                        <Row>
                        <Row><Col><h1><b>Welcome to RealityFlow</b></h1></Col></Row>
                        
                        <br></br>
                            <Col>
                                <Form>
                                    <Form.Group>
                                        <Form.Label>Workshop Name</Form.Label>
                                        <Form.Control readOnly placeholder="Enter Workshop Name" style={{'backgroundColor': 'lightGrey', 'pointerEvents': 'none'}} value={workshopData?.name ?? ""}/>
                                    </Form.Group>
                                    <br></br>

                                    <Form.Group>
                                        <Form.Label>Workshop Creator</Form.Label>
                                        <Form.Control readOnly placeholder="Enter Username" style={{'backgroundColor': 'lightGrey', 'pointerEvents': 'none'}} value={workshopData?.workshopOwner?.username ?? ""}/>
                                    </Form.Group>
                                    <br></br>

                                   {ifSignup ? <Form.Group>
                                        <Form.Label>Username</Form.Label>
                                        <Form.Control readOnly placeholder="Enter Username" style={{'backgroundColor': 'lightGrey', 'pointerEvents': 'none'}} value={`${workshopData?.name}-${workshopInput.headsetNumber}`}/>
                                    </Form.Group>: null}
                                    <br></br> 

                                    {workshopData ? <Form.Group controlId="formHeadsetNumber">
                                        <Form.Label>{ifSignup ? 'Select Your Headset Number' : 'Workshop Username'}</Form.Label>
                                        {
                                            ifSignup ? 
                                            <Form.Control as="select" name="headsetNumber" onChange={e => onChange(e, 'headsetNumber')}>
                                            <option value="" disabled>Select a headset number</option>
                                                {(ifSignup ? availableHeadsets : getExcludedNumbers(availableHeadsets, workshopData?.participantAmount ?? 0)).map((option, index) => (
                                                    <option key={index} value={option}>
                                                        {option}
                                                </option>
                                                ))}
                                            </Form.Control> : 
                                            <Form.Control placeholder={`${workshopData.name}-Your_Headset_Number`} onChange={e => onChange(e, 'username')}/>
                                        }
                                       
                                    </Form.Group> : null}
                                    <br></br>


                                    <Button className = "defaultButton" variant="primary"  onClick={() => navigate('../')}>
                                        Home
                                    </Button>
                                    <Button className = "defaultButton" variant="primary" style={{marginLeft: '2%'}} onClick={() => {ifSignup ? signUpWorkshop() : signInWorkshop()}}>{ifSignup ? 'Sign Up' : 'Login Workshop'}</Button>
                                
                                </Form>
                                <br></br>
                                <Row><Col><a style={{fontSize:'25px'}} href="" onClick={(e) => {
                                    e.preventDefault()
     
                                    setIfSignup(!ifSignup)
                                }}
                                >{ifSignup ? 'Already a Participant? Sign In': 'Back to Sign Up'}</a></Col></Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
