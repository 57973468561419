
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import { ListGroupItem } from 'react-bootstrap';
import { ListGroup, Button } from 'react-bootstrap';
import {gql, useMutation, useQuery} from '@apollo/client';
import { Cookies } from 'react-cookie';
import {headerWithToken} from './Helpers/utils'
import { useEffect, useState } from 'react';
import ProfilePicture from './ProfilePicture';
import { Collapse } from 'react-bootstrap';

const AddUser = ({projectId}) => {
        const cookies = new Cookies();
        const accesstoken = cookies.get("accessToken")
        const user_data = cookies.get("userData")
        const [searchText, setSearch] = useState('');
        const [coOwnerCollapse, setCoOwnerCollapse] = useState(true);
        const [participantCollapse, setParticipantCollapse] = useState(true);
        
        const GETOWNERS = gql`query GetProjectById($getProjectByIdId: String) {
          getProjectById(id: $getProjectByIdId) {
            projectOwner {
              id
            }
            projectCoOwners {
              id
              profileImg
              username
            }
            participants {
              id
              profileImg
              username
            }
          }
        }`
        const GETFRIENDS = gql`query GetFriendManage($userId: String) {
            getFriendManage(userId: $userId) {
              friends {
                username
                id
                profileImg
              }
            }
          }`

        const SEARCHUSERS = gql`query Search($searchText: String!) {
            search(searchText: $searchText) {
              people {
                username
                id
                profileImg
              }
            }
          }`
        const PROMOTEUSER = gql`mutation PromoteToCoOwner($input: EditProjectRoleInput!) {
          promoteToCoOwner(input: $input) {
            id
          }
        }`

        const DEMOTEUSER = gql`mutation DemoteToParticipant($input: EditProjectRoleInput!) {
          demoteToParticipant(input: $input) {
            id
          }
        }`

        const REMOVEUSER = gql`mutation RemoveFromProject($input: EditProjectRoleInput!) {
          removeFromProject(input: $input) {
            id
          }
        }`

        const ADDUSER = gql`mutation InviteToProject($input: EditProjectRoleInput!) {
          inviteToProject(input: $input) {
            id
          }
        }`

          const ownerData = useQuery(GETOWNERS, {
            variables: {
              "getProjectByIdId": projectId
            },
            context: headerWithToken(accesstoken)
          });
          const friendData = useQuery(GETFRIENDS, {
            variables: {"userId": user_data.id},
            context: headerWithToken(accesstoken)
          });

          const searchData = useQuery(SEARCHUSERS, {
            variables: {"searchText": searchText},
            context: headerWithToken(accesstoken)
          })

          const [promoteUser] = useMutation(PROMOTEUSER);
          const [demoteUser] = useMutation(DEMOTEUSER);
          const [removeUser] = useMutation(REMOVEUSER);
          const [addUser] = useMutation(ADDUSER);

        useEffect(()=>{
          ownerData.refetch();
          friendData.refetch();
          searchData.refetch();
      })
     
          if(friendData.loading){
            return(
                <div>Loading...</div>
            )
          }
          
          const onChange = (e) =>{
            setSearch(e.target.value);
          }

          const owner = (ownerData.data) ? ownerData.data.getProjectById.projectOwner : null;
          const coOwners = (ownerData.data) ? ownerData.data.getProjectById.projectCoOwners : null;
          const participants = (ownerData.data) ? ownerData.data.getProjectById.participants : null;
          const displayData = (searchText === '' || searchData.loading === true) ? friendData.data.getFriendManage.friends : searchData.data.search.people;

          const checkInProject = (id) =>{
            var i = 0;
            if(id === owner.id) return true;
            for(i = 0; i<coOwners.length;i++){
              if(id === coOwners[i].id) return true;
            }
            for(i = 0; i<participants.length;i++){
              if(id === participants[i].id) return true;
            }
            return false;
          }

        return (
            <Col className='friendList'>
              <Row><Col><h6 style={{marginTop: "15px"}}><b>Co-Owners</b></h6></Col><Col><div style = {{textDecoration: 'underline', marginTop:"15px"}} onClick={()=>setCoOwnerCollapse(!coOwnerCollapse)}>{(coOwnerCollapse) ? "Hide" : "Show"}</div></Col></Row>
              <Collapse in={coOwnerCollapse}>
                <ListGroup>
                  {(coOwners) ? (
                    coOwners.map((person)=>(
                        <ListGroupItem className='friendItem' style={{marginBottom:"5px"}}>
                        
                        <Row style={{display:'flex', alignItems:'center', justifyContent:'center', marginBottom: '5px'}}>
                          <Col style={{paddingRight:'0', maxWidth:'33%'}}>
                              <ProfilePicture profileImg = {person.profileImg} useClass = 'smallProfilePicSettings'/>    
                          </Col>
                          <Col className='text-truncate' style={{paddingLeft:'0'}}>
                              {person.username}
                          </Col>
                        </Row>
                        <Row>
                          <Col></Col>
                            <Col>
                                 <Button onClick = {()=>demoteUser({variables: {
                                    "input": {
                                      "userId": person.id,
                                      "projectId": projectId
                                    }
                                  },
                                  context: headerWithToken(accesstoken)
                                })}>Demote to Participant</Button>
                            </Col>
                        </Row>
                         
                      </ListGroupItem>
                    ))
                  ): null}
                </ListGroup>
              </Collapse>
              <Row><Col><h6 style={{marginTop: "15px"}}><b>Participant</b></h6></Col><Col><div style = {{textDecoration: 'underline', marginTop:"15px"}} onClick={()=>setParticipantCollapse(!participantCollapse)}>{(participantCollapse) ? "Hide" : "Show"}</div></Col></Row>
              <Collapse in={participantCollapse}>
                <ListGroup>
                  {(participants) ? (
                    participants.map((person)=>(
                        <ListGroupItem className='friendItem' style={{marginBottom:"5px"}}>
                        
                        <Row style={{display:'flex', alignItems:'center', justifyContent:'center', marginBottom: '5px'}}>
                          <Col style={{paddingRight:'0', maxWidth:'33%'}}>
                              <ProfilePicture profileImg = {person.profileImg} useClass = 'smallProfilePicSettings'/>    
                          </Col>
                          <Col className='text-truncate' style={{paddingLeft:'0'}}>
                              {person.username}
                          </Col>
                          
                        </Row>
                        <Row>
                            <Col>
                                 <Button onClick = {()=>promoteUser({variables: {
                                    "input": {
                                      "userId": person.id,
                                      "projectId": projectId
                                    }
                                  },
                                  context: headerWithToken(accesstoken)
                                  })}>Promote to Co-Owner</Button>
                            </Col>
                            <Col>
                                <Button onClick = {()=>removeUser({variables: {
                                    "input": {
                                      "userId": person.id,
                                      "projectId": projectId
                                    }
                                  },
                                  context: headerWithToken(accesstoken)
                                  })}>Remove from Project</Button>
                            </Col>
                        </Row>
                         
                      </ListGroupItem>
                    ))
                  ): null}
                </ListGroup>
              </Collapse>
              <h6 style={{marginTop: "15px"}}><b>Add User</b> </h6>
              <input placeholder='Search Users' onChange={onChange} style={{marginBottom: "10px"}}></input>
              {(!searchData.loading && ownerData.data) ? (<ListGroup style={{maxHeight: '60vh', overflowY: "auto"}}>
                   {displayData.map((friend) => (
                    (friend.id !== user_data.id && !checkInProject(friend.id)) ? (
                    <ListGroupItem className='friendItem' style={{marginBottom:"5px"}}>
                        
                    <Row style={{display:'flex', alignItems:'center', justifyContent:'center', marginBottom: '5px'}}>
                      <Col style={{paddingRight:'0', maxWidth:'33%'}}>
                          <ProfilePicture profileImg = {friend.profileImg} useClass = 'smallProfilePicSettings'/>    
                      </Col>
                      <Col className='text-truncate' style={{paddingLeft:'0'}}>
                          {friend.username}
                      </Col>
                      
                    </Row>
                    <Row>
                        <Col>
                           <Button onClick = {()=>addUser({variables: {
                                    "input": {
                                      "userId": friend.id,
                                      "projectId": projectId
                                    }
                                  },
                                  context: headerWithToken(accesstoken)
                                  })}>
                                Add To Project
                            </Button>
                        </Col>
                    </Row>
                     
                </ListGroupItem>): null
                  ))}
              </ListGroup>) : "Loading..."}
            </Col>
        )
                
    }
export default AddUser;

