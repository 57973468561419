
import React, {useEffect, useState} from 'react';
import { Button } from 'react-bootstrap';
import {
  rectIntersection,
  DndContext, 
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
  SortableContext,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import {SortableItem} from './SortableItem';
import UserProjectsDisplay from './UserProjectsDisplay';

export default function RearrangeImages({images, onImageMove}) {
  var projects = []
  //For loop generates array of images
  for(var i = 1; i <= images.length ; i++) {
    projects.push({
      id: i,
      image: require(`../../public/images/${images[i-1]}`),
      imageName: images[i-1]
    })
  }
  const [, setActiveId] = useState(null);
  const [items, setItems] = useState(projects);
  const [render, setRender] = useState(false);

  useEffect(()=>{
    setRender(false);
    onImageMove(items);
  }, [render, items, onImageMove])
  
  const sensors = useSensors(
    useSensor(PointerSensor),
  );
  

  function deleteImage(item) {
    if(items.length === 1) return;
    var tempItems = items;
    const index = tempItems.indexOf(item);
    if (index > -1) { // only splice array when item is found
      tempItems.splice(index, 1); // 2nd parameter means remove one item only
    }
    setItems(tempItems);
    setRender(true);
  }

  return (
    <DndContext 
      sensors={sensors}
      collisionDetection={rectIntersection}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}

    >
      <SortableContext 
        items={items}
        strategy={rectSortingStrategy}
      >
        <UserProjectsDisplay colCount={(items.length < 3) ? items.length : 3}>
        {items.map(item => <><SortableItem key={item.id} id={item.id} image={item.image} imageName={item.imageName}/> {(items.length > 1) ? <Button style = {{marginBottom:"5px"}} onClick={()=>deleteImage(item)}>Delete</Button>: null}</>)}
        </UserProjectsDisplay>
      </SortableContext>
      {/*<DragOverlay>
               {activeId ? (
                  <SortableItem item={activeId} image={items[items.findIndex(item => item.id === activeId)].image} />
                 ) : null}
            </DragOverlay> */}
    </DndContext>
  );
  
  function handleDragStart(event) {
    const {active} = event;
    setActiveId(active.id);
  }
  

  function handleDragEnd(event) {
    const {active, over} = event;
    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex(item => item.id === active.id);
        const newIndex = items.findIndex(item => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
    
    setActiveId(null);
  }
}
