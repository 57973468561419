import Carousel from 'react-bootstrap/Carousel';

export default function RfCarousel({images}) {

    var imageArray = [];
    for(var i = 0 ; i < images.length ; i++){
        imageArray.push({
            id : i,
            src: `../images/${images[i]}`
        })
    }
    return (
        
        <Carousel color='#282c34'>
                            {imageArray.map(image => <Carousel.Item key={image.id}>
                                <img
                                    className="d-block w-100"
                                    src={image.src}
                                    alt={"slide " + image.id}
                                    style ={{maxHeight: "500px", maxWidth: "1000px"}}
                                />
                            </Carousel.Item>)}
        </Carousel>

    );
}