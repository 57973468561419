
import {Row,Button, Col} from 'react-bootstrap';
import {gql, useMutation} from '@apollo/client'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import RfNavbar from '../Components/Navbar';
import { Cookies } from 'react-cookie';
import { headerWithToken , kickUser} from '../Components/Helpers/utils';

const GETCODE = gql`mutation RequestOTPVerification($input: RequestOTPVerificationInput!) {
  requestOTPVerification(input: $input) {
    otpCode
    OTPVerification {
      id
    }
  }
}`
  
export default function OTPCode() {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const accesstoken = cookies.get("accessToken");
    const user_data = cookies.get("userData");
    const [getCode, { data, error}] = useMutation(GETCODE)
    
    useEffect(() => {
      if(accesstoken == null || user_data == null){
        console.log("NO ACCESSTOKEN")
        navigate('/')
    }

        doGetCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

      const doGetCode = () =>{
        getCode({variables: {"input": {"userId" : user_data.id}}, context: headerWithToken(accesstoken)});
      }

      if(error) {
        if(error.message === 'Not Authenticated'){
            kickUser(navigate, cookies);
        }
    }
    console.log(data);
    return (
        <>
        <RfNavbar/>
        <Row className='SignLoginMainRow'>
            <Col style={{display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:"rgba(17, 22, 35, 0)"}}>
                <Row>
                    <Col style={{backgroundColor:"rgba(9,9,9,0.6)",padding:'50px', borderRadius:'25px', color: "white", maxWidth: "500px"}}>
                        
                        <Row>
                        <Row><Col><h1><b>Your One Time Pass Code</b></h1></Col></Row>
                        <br></br>
                        <Row><Col><h4>{(data) ? data.requestOTPVerification.otpCode : "0000"}</h4></Col></Row>
                        <br></br>
                        <Row><Col><Button onClick = {()=>doGetCode()}>Generate new code</Button></Col></Row>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
        </>
    )
}