import { useNavigate } from "react-router-dom";
import {Row, Col, Button, Form} from 'react-bootstrap';
import {gql, useQuery, useMutation} from '@apollo/client'
import {useCookies, Cookies } from "react-cookie";
import RfNavbar from "../Components/Navbar";
import editIcon from '../Images/editIcon.png'
import { useState} from "react";
import Modal from 'react-bootstrap/Modal';
import UserProjectsDisplay from "../Components/UserProjectsDisplay";
import ProfilePicture from "../Components/ProfilePicture";
import { ChromePicker } from 'react-color';
import { headerWithToken, kickUser } from "../Components/Helpers/utils";
import SettingsLoading from "../Components/SettingsLoading";

function importAll(r) {
    let images = [];
    r.keys().map((item, index) => images.push(item.replace('./', '')));
    return images;
  }
  
  const images = importAll(require.context('../Images/ProfilePictures', false, /\.(png|jpe?g|svg)$/));
  const GETUSERDATA = gql`query GetUserById($getUserByIdId: String!) {
    getUserById(id: $getUserByIdId) {
      profileImg
      bio
    }
  }`

  const SAVECHANGES = gql`mutation EditProfile($input: EditProfileInput) {
    editProfile(input: $input) {
      id
    }
  }`
export default function SettingPage() {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const accesstoken = cookies.get("accessToken")
    const user_data = cookies.get("userData")
    const [nameStateFlag, setNameState] = useState(false);
    const [userNameFlag] = useState(false);
    const [values, setValues] = useState({});
    const [show, setShow] = useState(false);
    // const [selected, setSelected] = useState( new Array(images.length).fill(false));
    const [, setCookie] = useCookies(['accessToken', 'userData'])
    const [currentFace, setFace] = useState();
    const [resFace, setResFace] = useState()
    const [resColor, setResColor] = useState()
    const [popFlag, setPopFlag] = useState(true);
    const [saveChanges, saveData] = useMutation(SAVECHANGES);
      const {data, loading, error} = useQuery(GETUSERDATA, {
        variables: {"getUserByIdId": user_data.id},
        context: headerWithToken(accesstoken)
      });
      if(error) {
        if(error.message === 'Not Authenticated'){
            kickUser(navigate, cookies);
        }
    }
      const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setValues({...values, [name]: value });
    }
    
     const onSubmit = (e) => {
        e.preventDefault()
        saveChanges({
            variables: {
                "input": {
                "id" : user_data.id,
                  "firstName": values.first,
                  "lastName": values.last,
                  "bio": values.about,
                  "profileImg":  color + " " + currentFace
                }
              },
            context: headerWithToken(accesstoken)})
        
     }
     
     const handleShow = () => setShow(true);
     const handleClose = () => {
        setResColor(color);
        setResFace(currentFace);
        setShow(false);
     };
     const handleNegativeClose = () => {
        setFace(resFace);
        setColor(resColor);
        setShow(false);
     };
     if(saveData.data){
        var newUserData = user_data;
        newUserData.firstName = values.first;
        newUserData.lastName = values.last;
        setCookie('userData', newUserData, {path: '/'})
       navigate("../account?acc=" + user_data.id)
     }
    //Switch green to user's current color saved
    const [color, setColor] = useState('green');

    const onColorChange = color => {
        setColor(color.hex);
    };

    const handleSelect = (face) => {
        setFace(face)
    }

    const onNameClick = () => {
        setNameState(true);
    }
    // const onUserNameClick = () => {
    //     setUserNameState(true);
    // }

    const styles = {
        'default': {
            picker: {
                background: 'rgba(0,0,0,0)',
                boxShadow: 'none',
                borderRadius: '10px'
            }
        }
    }
    if(loading) {
        return  (<SettingsLoading></SettingsLoading>)
      }

    if(data && popFlag){
        const pfpElements = data.getUserById.profileImg.split(" ")
        setColor(pfpElements[0])
        setResColor(pfpElements[0])
        setFace(pfpElements[1])
        setResFace(pfpElements[1])
        values.first = user_data.firstName;
        values.last = user_data.lastName;
        values.username = user_data.username;
        values.about = data.getUserById.bio;
        setPopFlag(false)
    }


    return (
        <>
            <RfNavbar/>
        <Form onSubmit = {onSubmit}>
            <Row className="defaultPage" style={{padding:'5%'}}>
                
                <Col style={{paddingBottom:'15%'}}>
                    
                    <Row  style={{textAlign:'center', paddingBottom:'7%'}}>
                        <Form.Group  as={Col} controlId="userProfilePic">
                            <Button className="rounded-circle profilePicButton" onClick={handleShow} style={{background:resColor}}><ProfilePicture profileImg={"#" + resColor + " " + resFace} useClass={'accountImageSettings2'} /></Button>
                            
                            <Modal show={show} onHide={handleNegativeClose} className='modal-lg'>
                            <Modal.Header>
                                <Modal.Title>Select New Profile Picture</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{marginBottom:'5%'}}>
                                <Row style={{justifyContent:'center', marginTop:'2%'}}><ChromePicker color={color} onChange={onColorChange} width='60%' styles={styles} disableAlpha={true}/></Row>
                                <Row>                                   
                                    <UserProjectsDisplay colCount={3}>
                                        {images.map(face => <Button className="rounded-circle profilePicButton2" onClick={()=>handleSelect(face)} style={{background:color, border: face === currentFace ? 'solid 10px white' : 'none'}}><ProfilePicture profileImg = {"#" + color + " " + face} useClass = {"accountImageSettings2"}/></Button>)}
                                    </UserProjectsDisplay>
                                </Row>     
                                
                            </Modal.Body>
                            <Modal.Footer style={{justifyContent:'center'}}>
                                <Button onClick={handleNegativeClose} className='rejectButton2 rounded-circle'>
                                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                <path class="checkmark__check" fill="none" d="M16 16 36 36 M36 16 16 36"/>
            </svg>
                                </Button>
                                <Button onClick={handleClose} className='acceptButton2 rounded-circle'>
                                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>
                                </Button>
                            </Modal.Footer>
                            </Modal>
                        </Form.Group >
                        <Col style={{maxWidth:'40%'}}>
                        <Row >
                            <Col >
                                <h2>About Me</h2>
                                <Form.Group controlId="aboutMe">
                                <Form.Control required size="md" as="textarea" type="text" name="about" rows={12} defaultValue = {data.getUserById.bio} className='editText' onChange={onChange} style={{textAlign:'left', marginBottom:'2%'}}>
                                </Form.Control>
                                </Form.Group>
                            </Col>
                            </Row>
                        </Col>
                    </Row>
                    <svg className="settingsVector"></svg>
                    <Row style={{paddingTop:'1%', paddingBottom:'2%'}}>
                        <Col>
                        
                            <Row style={{paddingBottom:'1%'}}>
                                <Col style={{textAlign:'left'}}><h2>My account</h2></Col>
                            </Row>
                            <svg className="settingsVector"></svg>
                            <Row>
                                <Col >
                                    <Row style={{paddingTop:'3%'}}>
                                        <Col><p>NAME</p></Col>
                                        {nameStateFlag === true ? 
                                        <>
                                        <Form.Group controlId="firstName" as={Col} style={{maxWidth:'25%'}}>
                                            <Form.Control required size="md" type="text" name="first" defaultValue={user_data.firstName} className='editText' onChange={onChange}></Form.Control>
                                        </Form.Group> 
                                        <Form.Group controlId="lastName" as={Col} style={{maxWidth:'25%'}}>
                                            <Form.Control required size="md" type="text" name="last" defaultValue={user_data.lastName} className='editText' onChange={onChange}></Form.Control>
                                        </Form.Group>
                                        </>: 
                                        <Col><p>{user_data.firstName + " " + user_data.lastName}</p></Col>}
                                    </Row>
                                    <Row style={{marginTop:'2%'}}>
                                        <Col><p>USERNAME</p></Col>
                                        {userNameFlag === true ?  
                                        <Form.Group controlId="userName" as={Col} >
                                            <Form.Control required size="md" type="text" name="username" defaultValue={user_data.username} className='editText' onChange={onChange}></Form.Control>
                                        </Form.Group>  : <Col><p>{user_data.username}</p></Col>}
                                    </Row>
                                    <Row style={{marginTop:'2%'}}>
                                        <Col><p>EMAIL ADDRESS</p></Col>
                                        <Col className="text-truncate"><p>{user_data.email}</p></Col>
                                    </Row>
                                </Col>
                                <Col style={{maxWidth:'20%', paddingTop:'2%'}}>
                                    <Row style={{marginTop:'3%'}}><Col><Button className="settingsButton1" onClick={onNameClick}><img alt="edit-icon" src={editIcon} style={{width:'20px', height:'20px'}}/></Button></Col></Row>
                                    {/*<Row style={{marginTop:'3%'}}><Col><Button className="settingsButton1" onClick={onUserNameClick}><img src={editIcon} style={{width:'20px', height:'20px'}}/></Button></Col></Row>*/}
                                    <Row><Col></Col></Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col></Col>
                                <Col>
                                    <Button className = "defaultButton" variant="primary" type="submit" style={{marginTop:'10%', marginBottom:'2%'}}>
                                    Save
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
        </>
    )
}

