
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import image2 from '../Images/IMG_2149.png';
import RfNavbar from "../Components/Navbar"
import { Link } from 'react-router-dom';

export default function LandingPage(){

    return (
        <>
        
        <Row className="defaultPage">
        <RfNavbar></RfNavbar>
            <Col style={{padding:'0px'}}>
                <Row style={{padding:'0px', margin:'0 auto', width:'100%', height:'1000px', backgroundImage:`url(${image2})`, backgroundSize:'100%, 100%, contain', backgroundRepeat:'no-repeat'}}>
                    <Col >
                        <Row style={{height:'28%'}}></Row>
                        <Row style={{ alignItems:'right', justifyContent:'right'}}>
                            <Col className="prevent-select col-lg-5 col-md-6 col-sm-7 col-xs-6" style = {{backgroundColor: "#4929B2", color: "white", borderRadius:'10px', paddingTop:'2%', paddingBottom:'2%', opacity:'.9'}}>
                            <h2><b>Create, Play, Learn</b></h2>
                            <p style={{ maxWidth:'80%', margin:'0 auto', justifyContent:'center', textAlign:'center'}}>Welcome to Reality Flow folks, a Virtual Reality application that gives you the tools to create a new world for yourself and others. Our innovative visual scripting solutions allow anyone to learn the basics of programming and take full control of their sandbox work. You will find Reality Flow to be easy to use while maintaining a deep toolkit, like competing applications, so that you will only be limited by the creativity of your imagination. At any time you can download projects from other creators via our Discovery Page, we hope you find a passion for programming and more importantly enjoy yourself.</p>
                            </Col>
                            <Col className=" col-lg-1 col-md-1 col-sm-1 d-none d-sm-block"></Col>
                        </Row>
                    </Col>
                </Row>
                <Row >
                    <Col style={{paddingTop:'2%', paddingBottom:'10%'}}>
                        <Row style={{marginBottom:'2%'}} className='prevent-select'><Col><h2>Download Information</h2></Col></Row>
                        <Row><Col><Link to="/RFReadme.txt" target = "_blank" download><Button variant="primary" className="landingButton prevent-select">Download</Button></Link>{' '}</Col></Row>
                    </Col>
                </Row>
            </Col>
        </Row>
        </>
    )
}