import {Row, Col, Button, Form} from 'react-bootstrap';
import RfNavbar from './Navbar';

const categories = ['Education', 'Game', 'Historical', 'Science'];
const EditProjLoading = () =>{

    return (
        <Row className = "defaultPage ">
            <RfNavbar></RfNavbar>
            <Form>
                <Col>
                    <Row style = {{paddingTop: "50px"}}>
                        <Col style={{maxWidth:'1%'}}></Col>
                        <Col style={{ minWidth:'60%'}}>
                        <h4 style={{paddingBottom:'0%', position:'relative', marginBottom:'2%'}}>Images: <svg className="editVector"></svg></h4>
                        
                        </Col>
                        <Col style={{paddingLeft:'5%', paddingRight:'5%'}}>
                            <h4 style={{paddingBottom:'0%', position:'relative', marginBottom:'2%'}}>Title: <svg className="editVector"></svg></h4>
                            
                            <Form.Group controlId="projectTitle" as={Row} style={{paddingBottom:'5%'}}><Form.Control required size="lg" type="text" name="projectName" defaultValue={"Project"}style={{marginBottom:'2%'}} className='editText'></Form.Control></Form.Group>
                            <h4 style={{paddingBottom:'0%', position:'relative', marginBottom:'2%'}}>Description: <svg className="editVector"></svg></h4>
                            <Form.Group controlId="projectDescription" as={Row} style={{paddingBottom:'5%'}} ><Form.Control required size="md"  maxLength="256" as="textarea" type="text" name="description" rows={5} className='editText' defaultValue={"Description"}></Form.Control></Form.Group>
                            <h4 style={{paddingBottom:'0%', position:'relative', marginBottom:'2%'}}>Details: <svg className="editVector"></svg></h4>
                            <Form.Group controlId="projectDetails" as={Row} style={{paddingBottom:'5%'}}>
                                        <Form.Control required size="sm" as="textarea" type="text" rows={12} className='editText' name = 'details' defaultValue={"details"}></Form.Control>
                            </Form.Group>
                            <h4 style={{paddingBottom:'0%', position:'relative', marginBottom:'2%'}}>Categories: <svg className="editVector"></svg></h4>
                            {categories.map((category, index) => (
                                            <div key={`check-${category}`} style ={{ marginLeft: "15px"}}>
                                    <Form.Check 
                                        type={"checkbox"}
                                        id={`${category}-check`}
                                        label={category}
                                        defaultChecked={false}
                                    />
                                    </div>
                                        ))}
                            <Form.Check 
                                        type={"checkbox"}
                                        id={`public-check`}
                                        name={'public'}
                                        label={"Public?"}
                                        defaultChecked={true}
                                    />

                            <Button className = "defaultButton" variant="primary" type="submit" style={{marginTop:'10%', marginBottom:'2%'}}>
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Form>
        </Row>
    )
}
export default EditProjLoading