import { Cookies } from "react-cookie";
import { gql, useMutation } from "@apollo/client";
import { useEffect, useState, useRef, useCallback } from "react";
import { FiTriangle as TriangleIcon } from "react-icons/fi";
import { FiTrash2 as TrashIcon } from "react-icons/fi";
import { FaRegHeart as HeartIcon } from "react-icons/fa";
import { IoHeart as AddIcon, IoHeartDislikeSharp as RemoveIcon } from "react-icons/io5";
import { headerWithToken } from "./Helpers/utils";
import LoadingSpinner from "./LoadingSpinner";

import Renderer, { setModels, getGL, useLoad, usUnload, useUnload } from './Renderer/Renderer.js';
import { createGLB, createModel } from './Renderer/FileUtils/GLBParser.js';


const DELETE = gql`
mutation Delete($input: String!) {
    deleteModel(modelId: $input) {
        name
    }
}
`;
const FOLLOW = gql`
mutation Follow($input: String!) {
    followModel(modelId: $input) {
        name
    }
}
`;
const UNFOLLOW = gql`
mutation unFollow($input: String!) {
    unFollowModel(modelId: $input) {
        name
    }
}
`;


const ACCESSTOKEN = 'AIzaSyAXJ3FRhOCUmigqHOBNkmHMdwwQe6p9m2c';


export default function({model, reload, setReload, viewer}) {
    const cookies = new Cookies();
    const accountToken = cookies.get("accessToken");
    const modal = useRef(0);
    const [followed, setFollowed] = useState(false);
    const [submitDelete] = useMutation(DELETE);
    const [submitFollow] = useMutation(FOLLOW);
    const [submitUnFollow] = useMutation(UNFOLLOW);
    const [modelLoaded, setModelLoaded] = useState(false);
    const [modelFailed, setModelFailed] = useState(false);

    // Model Loading
    const loadModel = useCallback(async () => {
        useUnload();
        await useLoad();
        setModels([]);
        setModelLoaded(false);
        setModelFailed(false);
        try {
            let fileId = model.downloadURL.substr(model.downloadURL.indexOf("=") + 1);
            let response = await fetch(
                `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media&key=${ACCESSTOKEN}`
            );
            let data = await response.arrayBuffer();
            let glb = await createGLB(data);
            if (glb == null)
                throw Error("Failed to process file"); 
            let loadedModel = await createModel(getGL().current, glb);
            setModels([loadedModel]);
            setModelLoaded(true);
        } catch (err) {
            console.error(err);
            setModelFailed(true);
        }
    }, [model]);

    useEffect(_ => {
        if (reload)
            setReload(false);
        if (!model) {
            modal.current.close();
            return;
        }

        setFollowed(model.followersIds.includes(viewer));
        modal.current.showModal();

        // Start download process
        loadModel();
    }, [model, reload]);


    // Model Interactions
    const deleteModel = async _ => {
        if (!window.confirm("Are you sure you want to delete: " + model.name))
            return;

        const deletedModel = await submitDelete({
            variables: {
                input: model.id
            },
            context: headerWithToken(accountToken)
        });

        modal.current.close();
    };

    const followModel = async _ => {
        const followedModel = await submitFollow({
            variables: {
                input: model.id
            },
            context: headerWithToken(accountToken)
        });
        if(followedModel) {
            setFollowed(true);
        } else {
            alert("Follow request failed.");
        }
    }

    const unFollowModel = async _ => {
        const unFollowedModel = await submitUnFollow({
            variables: {
                input: model.id
            },
            context: headerWithToken(accountToken)
        });
        if (unFollowModel) {
            setFollowed(false);
        } else {
            alert("Unfollow request failed.");
        }
    }

    // HTML
    return (
        <dialog className="model-viewer" id="model-viewer" ref={modal}
                style={{userSelect: "none"}}>
            <div className="model-viewer--interactions">
                <button onClick={_ => modal.current.close()}>X</button>
                {viewer && model ? viewer == model.userId
                    ? <button onClick={deleteModel}><TrashIcon /></button>
                    : followed
                        ? <button onClick={unFollowModel}><RemoveIcon /></button>
                        : <button onClick={followModel}><AddIcon /></button>
                    : null}
            </div>
            
            <div className="model-viewer--details">
                <div>{model?.triangles} <TriangleIcon /></div>
                <div>{model?.followersIds.length} <HeartIcon /></div>
            </div>

            <Renderer height="100%" width="100%" />
            { !modelLoaded ?
                <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"}}>
                    {
                        modelFailed
                            ? <h3>Failed</h3>
                            : <>
                                <h3>Loading</h3>
                                <LoadingSpinner />
                            </>
                    }
                </div>
                : null
            }

        </dialog>
    )
}