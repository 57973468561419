
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ListGroupItem } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';
import {gql, useQuery} from '@apollo/client';
import { Cookies } from 'react-cookie';
import {headerWithToken, kickUser} from '../Helpers/utils'
import FriendRequestDisplay from './FriendRequestDisplay';
import { useEffect, useState } from 'react';
import ProfilePicture from '../ProfilePicture';
import NavDropdown from 'react-bootstrap/NavDropdown';


function FriendDisplay () {
        const cookies = new Cookies();
        const navigate = useNavigate();
        const accesstoken = cookies.get("accessToken")
        const user_data = cookies.get("userData")
        const [isRender, setRender] = useState(false);
        const renderObj = {
          "isRender": isRender,
          "setRender": setRender
        }
        
        const GETFRIENDS = gql`query GetFriendManage($userId: String) {
            getFriendManage(userId: $userId) {
              friends {
                username
                id
                profileImg
              }
            }
          }`

          const {data, loading, error, refetch} = useQuery(GETFRIENDS, {
            variables: {"userId": user_data.id},
            context: headerWithToken(accesstoken)
          });
          if(error) {
            if(error.message === 'Not Authenticated'){
                kickUser(navigate, cookies);
            }
        }
        
          if(accesstoken == null || user_data == null){
              console.log("NO ACCESSTOKEN")
              navigate('/')
          }
        useEffect(()=>{
            refetch()
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [renderObj.isRender])
     
          if(loading){
            return(
              <Col className='friendList'>
              <h6 style={{textDecoration: 'underline' }}><b>Friend Requests</b></h6>
              <h6 style={{textDecoration: 'underline', marginTop: "15px"}}><b>Friends</b> </h6>
            </Col>
            )
          }
          
        return (
            <Col className='friendList'>
              <h6 style={{textDecoration: 'underline' }}><b>Friend Requests</b></h6>
              <FriendRequestDisplay renderObj = {renderObj} userId = {user_data.id} accesstoken = {accesstoken}/>
              <h6 style={{textDecoration: 'underline', marginTop: "15px"}}><b>Friends</b> </h6>
              <ListGroup style={{maxHeight: '60vh', overflowY: "auto"}}>
                  {data.getFriendManage.friends.map((friend) => (
                    <NavDropdown.Item className='friendItem' href={`/account?acc=${friend.id}`} style={{textDecoration: 'none',  border: 'solid 1px white', borderRadius:'5px', marginTop:'3%'}}>
                      <ListGroupItem style={{background:'transparent', border:'none', color:'white' }}>
                        
                          <Row style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <Col style={{paddingRight:'0', maxWidth:'33%'}}>
                                <ProfilePicture profileImg = {friend.profileImg} useClass = 'smallProfilePicSettings'/>    
                            </Col>
                            <Col className='text-truncate' style={{paddingLeft:'0'}}>
                                {friend.username}
                            </Col>
                          </Row>
                           
                      </ListGroupItem>
                      </NavDropdown.Item> 
                  ))}
              </ListGroup>
            </Col>
        )
                
    }
export default FriendDisplay;

