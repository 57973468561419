import {Row, Col, Button, Form} from 'react-bootstrap';
import RfNavbar from '../Components/Navbar'

import { useEffect, useState } from 'react';
import { gql, useMutation} from '@apollo/client';
import {Cookies }from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import {headerWithToken} from '../Components/Helpers/utils';

export const sendCreateProjToFediVerse = async (newProject, accesstoken, user_data) => {
    try {
      const toMyOutbox = {
        "@context": ["https://www.w3.org/ns/activitystreams", {accessToken: accesstoken}],
        "type": "Create",
        "actor": {...user_data, url: `${process.env.REACT_APP_API}/~${user_data.username}`, type: 'Person'},
        "name": "Create Project on Fediverse",
        "object": newProject,
      };

      const myServer = (process.env.REACT_APP_API ?? "http://localhost:4000") + '/outbox';

      const toMyOutboxRequest = await fetch(myServer, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(toMyOutbox),
      });
    if(toMyOutboxRequest.status === 201) {
      console.log("Project sent to Fediverse")
    }
    
    } catch (e) {
      console.log(e);
    }
}

const categories = ['Education', 'Game', 'Historical', 'Science'];
export default function CreateProjectPage() {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const accesstoken = cookies.get("accessToken")
    const user_data = cookies.get("userData")

    useEffect(()=> {
        if(accesstoken == null || user_data == null){
            console.log("NO ACCESSTOKEN")
            navigate('/')
        }
    })
    const CREATEPROJECT = gql`mutation CreateProject($input: CreateProjectInput) {
        createProject(input: $input) {
          id
        }
      }`

    const [createProject, {data}] = useMutation(CREATEPROJECT, {context:headerWithToken(accesstoken)})
    const [checkedState, setCheckedState] = useState(
        new Array(categories.length).fill(false)
      );
    const [publicCheckState, setPublicCheckState] = useState(false);
    const [categoryState, setCategoryState] = useState()
    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setValues({...values, [name]: value });
        // console.log(name + ": " + value)
    }

    const [values, setValues] = useState({});

    const onSubmit = async (e) => {
      e.preventDefault();

      const newProject = {
        input: {
          projectName: values.projectName,
          projectOwnerId: user_data.id,
          description: values.description,
          details: values.details,
          categories: categoryState,
          isPublic: publicCheckState,
          gallery: ["defaultThumbnail.jpeg"],
          globalId: `${process.env.REACT_APP_PUBLIC_URL}~${user_data.id}~${new Date().getTime()}`
        },
      };

      if(publicCheckState){
        newProject.input.publicUrl = `${process.env.REACT_APP_PUBLIC_URL}/~${user_data.username}`;
        sendCreateProjToFediVerse(newProject, accesstoken, user_data);
      }

      createProject({ variables: newProject });

    };

    const handleCategoryChange = (position) =>{
        const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);

        var arr = []
        for(var i=0;i<updatedCheckedState.length;i++){
            if(updatedCheckedState[i])  arr.push(categories[i])
        }

        setCategoryState(arr)
    }
    const handlePublicChange = () =>{
            setPublicCheckState(!publicCheckState)
    }

    if(data) {
        navigate(`../roomdetailpage?p=${data.createProject.id}`)
    }
    return (
                
        <Row >
            <RfNavbar></RfNavbar>
            <Col className='defaultPage' style={{paddingBottom:'5%'}}>
                <h1 style ={{marginTop: "50px"}}>Create New Project</h1>
            <Form style = {{ marginLeft: "25%", marginRight: "25%", marginTop: "50px", textAlign: "left"}} onSubmit={onSubmit}>
                                <Form.Group controlId="formProjectName">
                                        <Form.Label><h3>Project Name</h3></Form.Label>
                                        <Form.Control type="projectName" name = "projectName" placeholder="Project Name" className='editText' onChange = {onChange}/>
                                    </Form.Group>
                                    <Form.Text className="text-muted">
                                        Choose carefully, you can't change this.
                                        </Form.Text>
                                        <Form.Group className="mb-3" controlId="descriptonTextBox">
                                            <Form.Label><h3>Description</h3></Form.Label>
                                            <Form.Control  maxLength="256" as="textarea" name="description" rows={4} onChange = {onChange} className='editText'/>
                                        </Form.Group>
                                        <Form.Text className="text-muted">
                                        Description is limited to 256 characters
                                        </Form.Text>
                                        <Form.Group className="mb-3" controlId="descriptonTextBox">
                                            <Form.Label><h3>Details</h3></Form.Label>
                                            <Form.Control as="textarea" name="details" rows={4} onChange = {onChange} className='editText'/>
                                        </Form.Group>
                                    <h3>Select some categories for your project</h3>
                                    
                                        {categories.map((category, index) => (
                                            <div key={`check-${category}`} style ={{ marginLeft: "15px"}}>
                                    <Form.Check 
                                        type={"checkbox"}
                                        id={`${category}-check`}
                                        label={category}
                                        onChange = {() => handleCategoryChange(index)}
                                    />
                                    </div>
                                        ))}
                                    <Form.Check 
                                        type={"checkbox"}
                                        id={"public-check"}
                                        label={"Make Project Public?"}
                                        onChange = {handlePublicChange}
                                    />
                                    <Row>
                                        <Col style = {{display: 'flex', justifyContent: 'center', marginTop: '15px'}}>
                                    <Button className = "defaultButton" variant="primary" type="submit">
                                        Create Project
                                    </Button>
                                        </Col>
                                    </Row>
                                </Form>
            </Col>
        </Row>
    );
}