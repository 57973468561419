import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { IoMdMic, IoMdMicOff } from "react-icons/io";
import { Cookies } from 'react-cookie';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { useEffect, useCallback, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Unity, useUnityContext } from 'react-unity-webgl';
import LoadingSpinner from '../Components/LoadingSpinner';

const UNITYPATH = "/player/";

const GETWORKSHOPBYCODE = gql`
  query GetWorkshop($code: String!) {
    getWorkshopByCode(code: $code) {
      name
      id
      description
      participantAmount
      participants{
        id
        username
        email
        firstName
        lastName
        currentRoomId
      }
      instructors{
        id
        username
        email
        firstName
        lastName
      }
    }
  }
`;

export default function MonitorWorkshop() {

  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get("code");
  const participantId = queryParams.get("participantId");
  const cookies = new Cookies();
  const accessToken = cookies.get("accessToken");

  const [searchName, setSearchName] = React.useState(""); 
  const [currentWorkshopData, setCurrentWorkshopData] = useState(null);
  const [hideParticipants, setHideParticipants] = useState(false);
  const [currentParticipantId, setCurrentParticipantId] = useState(null);
  const [mute, setMute] = useState(false);

  const { data, refetch } = useQuery(GETWORKSHOPBYCODE, { variables: { code: code } });

  const getParticipants = useCallback(() => {
    refetch()
}, []);

  const { unityProvider, sendMessage, isLoaded, loadingProgression,
    addEventListener, removeEventListener } = useUnityContext({
    loaderUrl: UNITYPATH + "App.loader.js",
    dataUrl: UNITYPATH + "App.data",
    frameworkUrl: UNITYPATH + "App.framework.js",
    codeUrl: UNITYPATH + "App.wasm"
    });

const Awake = useCallback(() => {
  sendMessage("RealityFlowClient", "SetServer", window.location.protocol + "//"
    + window.location.host);
  sendMessage("RealityFlowClient", "Login", accessToken);
});


  const handleSelectParticipant = (e, participantId) => {
    e.preventDefault();
    setCurrentParticipantId(participantId)

    sendMessage(
      "MonitorManager", "Follow", participantId
  );
  }

  useEffect(() => {
    const interval = setInterval(getParticipants, 5000);

    return () => clearInterval(interval);
}, []);

  useEffect(() => {
    addEventListener("Awake", Awake);
    return () => {
        removeEventListener("Awake", Awake);
    };
}, [addEventListener, removeEventListener, Awake]);


  useEffect(() => {
    if (data) {
      setCurrentWorkshopData(data.getWorkshopByCode);
      setCurrentParticipantId(participantId);
    }

  }, [data]);


  return (
    <Row className="defaultPage SignLoginMainRow">
      <Col>
        <div style={{display: 'flex',
                background: "black",
                padding: ".5%",
                opacity: 0.8,
                width: "95%",
                borderRadius: 20,
                margin: '1% auto',}}>

                <Button  variant="outline-secondary" className="mx-2" style={{ fontSize: 20, flex: 1, color: 'white'}} onClick={() => {navigate('../participantList', {state: {code: code ?? ""}})}}>
                    Exit
                </Button>
                <Button  variant="outline-secondary" className="mx-2" style={{ fontSize: 20, flex: 1, color: 'white', minWidth: '200px'}} onClick={() => {setHideParticipants(!hideParticipants)}}>
                    {`${hideParticipants ? 'Show' : 'Hide'} Participants`}
                </Button>
                <h1
                className="mx-auto mt-2"
                style={{
                    flex: 12
                }}
                >
                {currentWorkshopData?.name ?? "Workshop Name"}
                </h1>
                <h1 style={{marginRight: '10px'}}>{mute ? <IoMdMicOff onClick={() => setMute(!mute)} style={{color: 'white', fontSize: '50px', cursor: 'pointer'}}/> : <IoMdMic onClick={() => setMute(!mute)} style={{color: 'white', fontSize: '50px', cursor: 'pointer'}}/>}</h1>
                <Button  variant="outline-secondary" className="mx-2" style={{ fontSize: 20, flex: 1, color: 'white'}}>
                    Collaborate
                </Button>
                <Button  variant="outline-secondary" className="mx-2" style={{ fontSize: 20, flex: 1, color: 'white'}}>
                    Settings
                </Button>
                <Button  variant="outline-secondary" className="mx-2" style={{fontSize: 20, flex: 1, color: 'white'}}>
                    Help
                </Button>
        </div>
        <Row style={{opacity: .8, marginLeft: '2%',  marginRight: '2%'}}>
          {hideParticipants ? null : <Col className="m-0 p-0" style={{ flex: 2, overflowX: 'hidden',maxHeight: '570px', overflowY: 'scroll', scrollbarWidth: 'thin', scrollbarColor: '#5e5e5e #2b2b2b'}}>
            <Row>
              <Col >
              <Form.Control
                type="search"
                placeholder="Search Participants"
                className="me-2"
                aria-label="Search"
                style = {{background: '#505050', color: 'white', fontSize: 25, width: '95%',height: '60px', backgroundColor: 'black', margin: '2% auto 2%'}}
                value={searchName}
                onChange = {e => {setSearchName(e.target.value)}}
                />
                {currentWorkshopData && currentWorkshopData.participants?.length > 0 && !hideParticipants ? currentWorkshopData.participants.map((participant, i) => (
                    participant.username.includes(searchName) ? <Row key={"p" + i} onClick={e => {handleSelectParticipant(e, participant.id)}}
                          style={{borderRadius: 20, background: currentParticipantId === participant.id ? 'grey' : 'black', padding: '10px 0', width: '90%', margin: '5px auto', cursor: 'pointer'}}>
                        <h4>{participant.username}</h4>
                        <h5>{`Name: ${participant.firstName ?? 'First '} ${participant.lastName ?? 'Last'}`}</h5>
                        <h5 style={{color: participant.currentRoomId? 'white' : 'lightcoral'}}>{`Status: ${participant.currentRoomId ? 'Active' : 'Inactive'}`}</h5>

                    </Row> : null
                )) : 
                null
                }
              </Col>
            </Row>
          </Col>}
          <Col className="m-0 p-0" style={{ flex: 6, backgroundColor: "black", height: '550px'}}>
          <div style={{width: "100%",
                aspectRatio: "1.8",
                minHeight: "400px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"}}>
                    { isLoaded || <>
                        <h2>{Math.round(loadingProgression * 100)}% Loaded</h2>
                        <LoadingSpinner />
                    </>}
                    <Unity style={{width: "100%",
                          height: "100%",
                          margin: "0", 
                          display: isLoaded ? "block" : "none" }} unityProvider={unityProvider} />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}