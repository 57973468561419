import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RfNavbar from '../Components/Navbar'
import RfDiscoveryCarousel from '../Components/RfDiscoveryCarousel';
import {gql, useQuery} from '@apollo/client';
import { headerWithToken, kickUser } from '../Components/Helpers/utils';
import { Cookies } from 'react-cookie';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function DiscoveryPage() {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const accesstoken = cookies.get("accessToken")
    const GETPROJECTS = gql`query GetPublicProjects {
        getPublicProjects {
          id
          gallery
          projectName
          categories
        }
      }`
      useEffect(()=>{
        if(accesstoken == null){
            console.log("NO ACCESSTOKEN")
            navigate('/')
        }
      })
      const {data, error} = useQuery(GETPROJECTS, {context: headerWithToken(accesstoken)})
      
      
      if(error) {
        if(error.message === 'Not Authenticated'){
            kickUser(navigate, cookies);
        }
    }
      const subCategories = () =>{
        const catProjects = {
            "Education" : [],
            "Game" : [],
            "Historical" : [],
            "Science" : []
        }
        if(data){
            Object.keys(catProjects).map((category)=>(
                data.getPublicProjects.map((project)=>(
                    (project.categories.includes(category)) ? 
                        catProjects[category].push(project) : null
                ))
            ))
        }
        return catProjects
      }
    return (
                
        <Row >
            <RfNavbar></RfNavbar>
            <Col className='defaultPage' style={{paddingBottom:'5%'}}>
                <RfDiscoveryCarousel title="Educational" projects={subCategories().Education}></RfDiscoveryCarousel>
                <RfDiscoveryCarousel title="Historical" projects={subCategories().Historical}></RfDiscoveryCarousel>
                <RfDiscoveryCarousel title="Games" projects={subCategories().Game}></RfDiscoveryCarousel>
                <RfDiscoveryCarousel title="Science" projects={subCategories().Science}></RfDiscoveryCarousel>            
            </Col>
        </Row>
    );
}