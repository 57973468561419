import React, { useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Button, Form, Modal} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Cookies } from 'react-cookie';
import { BsCopy } from "react-icons/bs";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { headerWithToken, kickUser } from "../Components/Helpers/utils";

const GETWORKSHOPS = gql`
  query GetWorkshopsByUserId($id: String!){
    getAllWorkshopsByUserId(id: $id) {
      name
      id
      description
      invitationCode
      participantAmount
      createAt
      workshopOwner{
        username
      }
      participants {
        username
      }
      instructors{
        id
        username
      }
    }
  }
`;

const ADDINSTRUCTOR = gql`mutation AddInstructor($input: addInstuctorInput) {
  addInstructorToWorkshop(input: $input) {
      name
  }
}`

const REMOVEINSTRUCTOR = gql`mutation RemoveInstructor($input: addInstuctorInput) {
  removeInstructorFromWorkshop(input: $input) {
      name
  }}`

const GETSEARCH = gql`query Search($searchText: String!) {
  search(searchText: $searchText) {
    people {
      username
      id
      profileImg
    }
  }
}`

const DELETEWORKSHOP = gql`mutation DeleteWorkshop($id: String) {
  deleteWorkshop(id: $id) {
      name
    }
  }`


const ShowWorkshop = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();  
  const accesstoken = cookies.get("accessToken")
  const user_data = cookies.get("userData")

  const [allWorkshops, setAllWorkshops] = React.useState([]);
  const [searchName, setSearchName] = React.useState(""); 
  const [foundPpl, setFoundPpl] = React.useState([])
  const [currentSearchPplWorkshop, setCurrentSearchPplWorkshop] = React.useState('')
  const [currentDeleteWorkshop, setCurrentDeleteWorkshop] = React.useState('')

  const [addInstructorReq] = useMutation(ADDINSTRUCTOR);
  const [removeInstructorReq] = useMutation(REMOVEINSTRUCTOR);
  const [deleteWorkshopReq] = useMutation(DELETEWORKSHOP);
  const { data, loading, refetch } = useQuery(GETWORKSHOPS, {variables: {id: user_data?.id ?? ""}});
  const {data: searchData} = useQuery(GETSEARCH, {
    variables: {"searchText": searchName},
    context: headerWithToken(accesstoken)
  })

  if (accesstoken == null || user_data == null) {
    navigate('/')
  }
  
  const handleClip = (e, code) => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_PUBLIC_URL ?? "http://localhost:3000"}/joinworkshop/${code}`)
    alert('Copied to clipboard!');

  }

  const handleAddPpl = async (e, uerId, workshopId) => {
    e.preventDefault();
    setSearchName("");
    
    const res = await addInstructorReq({variables: {input: {workshopId: workshopId, userId: uerId}}})
    refetch({id: user_data.id});
  }

  const handleRemovePpl = async (e, uerId, workshopId) => {
    e.preventDefault();
    setSearchName("");
    
    const res = await removeInstructorReq({variables: {input: {workshopId: workshopId, userId: uerId}}})
    refetch({id: user_data.id});
  }


  const handleDeleteWorkshop = async (e) => {
    e.preventDefault();
    const res = await deleteWorkshopReq({variables: {id: currentDeleteWorkshop}})

    setCurrentDeleteWorkshop('')

    refetch({id: user_data.id});
  }
  
  useEffect(() => {
    if (data) {
      setAllWorkshops(data.getAllWorkshopsByUserId);
    }

    if(searchData) {
      setFoundPpl(searchData.search.people)
    }
  }, [data, loading, searchData, refetch]);

  useEffect(() => {
    refetch()
  })

  return (
    <Row className="defaultPage SignLoginMainRow">
      <Col
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "50%",
          backgroundColor: "rgba(17, 22, 35, 0)",
        }}
      >
        <Row>
          <Col
            style={{
              backgroundColor: "rgba(9,9,9,0.6)",
              padding: "50px",
              borderRadius: "25px",
              color: "white",
            }}
          >
            <Row>
              <Row>
                <Col>
                  <h1>
                    <b>Welcome to RealityFlow</b>
                  </h1>
                </Col>
              </Row>

              <br></br>
              <Col>
                <Form>
                  <br></br>
                  <Form.Group controlId="">
                    <ul
                      style={{
                        width: "90%",
                        listStyleType: "none",
                        alignItems: "center",
                      }}
                    >
                      {allWorkshops
                        ? allWorkshops.map((workshop, i) => {
                            return (
                              <li
                                style={{
                                  background: "grey",
                                  opacity: 0.8,
                                  marginBottom: "2%",
                                  borderRadius: 20,
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  padding: "2%",
                                  zIndex: allWorkshops.length - i,
                                }}
                                key={i + 'keya'}
                              >
                                <div style={{ flex: 2 }}>
                                  <h2>{workshop.name}</h2>
                                  {workshop.participants
                                    ? workshop?.participants?.map(
                                        (participant, i) => {
                                          if (
                                            participant.role === "instructor"
                                          ) {
                                            return (
                                              <span style={{fontSize: 20}} key={i + "a"}>
                                                {participant.username}
                                              </span>
                                            );
                                          }
                                        }
                                      )
                                    : null}
                                  <h5>{`Participants: ${workshop.participants.length}`}</h5>
                                  <h6>{`Max Capacity: ${workshop.participantAmount}`}</h6>
                                  <h6>Created By: <a style={{color: 'white'}} href={`../search?s=${workshop.workshopOwner.username}`}>{workshop.workshopOwner.username}</a></h6>
                                </div>
                                <div style={{ flex: 2, overflow: "hidden" }}>
                                  <p
                                    style={{
                                      fontSize: 20,
                                      whiteSpace: "normal",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    {workshop.description}
                                  </p>
                                </div>
                                <div
                                  style={{ flex: 2, alignContent: "center"}}
                                >
                                  <h5>{`Invite Code: ${workshop.invitationCode}`}<span><BsCopy style={{ cursor: 'pointer', marginLeft: '5px' }} onClick={e => handleClip(e, workshop.invitationCode)}/></span></h5>
                                  <Button
                                    onClick = {() => {navigate("../participantList", {state: {code: workshop.invitationCode}})}}
                                    style={{fontSize: 20 }}
                                  >
                                    View
                                  </Button>
                                 {workshop.workshopOwner.username === user_data.username ? <Button
                                      onClick={() => setCurrentDeleteWorkshop(workshop.id)}
                                      style={{margin: '5px', fontSize: 20 }}
                                      variant="danger">
                                      Delete
                                  </Button>: null}
                                  <Modal show={currentDeleteWorkshop !== ''} onHide={()=>{setCurrentDeleteWorkshop('')}}>
                                  <Modal.Header closeButton>
                                      <Modal.Title>Confirm Delete</Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>Are you sure you want to delete this workshop?</Modal.Body>
                                  <Modal.Footer>
                                      <Button variant="secondary" onClick={() => {setCurrentDeleteWorkshop('')}}>
                                          Cancel
                                      </Button>
                                    <Button variant="danger" onClick={e => handleDeleteWorkshop(e)}>
                                          Delete
                                      </Button>
                                  </Modal.Footer>
                              </Modal>
                                </div>
                                <div style={{flex: 2, width: '20px', alignSelf: 'flex-start'}}>
                                    <NavDropdown style={{ fontSize: 20, }} title = {"instructors"}>
                                      {workshop?.instructors?.map((instructor, i) => <div key = {'efg' + i} style={{ display: 'flex', alignItems: 'center' }}><NavDropdown.Item href={`../search?s=${instructor.username}`}>{instructor.username}</NavDropdown.Item>
                                      {workshop.workshopOwner.username === user_data.username ? <a href="" onClick={e=> handleRemovePpl(e, instructor.id, workshop.id)} style={{margin: '0 10px'}}>delete</a> : null}</div>)}
                                    </NavDropdown>
                                    <div style={{display: "flex"}}>
                                      <Form.Control
                                          type="search"
                                          placeholder="Search instructor"
                                          className="me-2"
                                          aria-label="Search"
                                          style = {{background: '#505050', color: 'white', width: '100px', flex: 4}}
                                          value={workshop.id === currentSearchPplWorkshop ? searchName : ""}
                                          onChange = {e => {setSearchName(e.target.value); setCurrentSearchPplWorkshop(workshop.id)}}
                                          />
                                        <Button style={{ flex: 1}} onClick={() => {}}>Search</Button>
                                    </div>
                                   {searchName && workshop.id == currentSearchPplWorkshop ? 
                                   <div style={{textAlign: 'left', marginTop: '5px'}}>{foundPpl?.slice(0, 3)?.map((ppl, i) => (
                                      ppl.username.startsWith(searchName) ? <div key={"z" + i} style={{display: 'flex'}}>
                                        <span style={{flex: 3}}>{ppl.username + "  "}</span>
                                        <span style={{flex: 1}}><a href="" style={{color: 'white'}} onClick={(e) => handleAddPpl(e, ppl.id, workshop.id)}>Add</a></span>
                                      </div> : null
                                    ))}
                                    </div> : null}
                                    <Button
                                      style={{ flex: 1, marginTop: '30px' }}
                                      onClick={() => navigate(`../surveys?workshopId=${workshop.id}`)}
                                    >
                                    Surveys
                                  </Button>
                                </div>
                              </li>
                            );
                          })
                        : null}
                    </ul>
                  </Form.Group>
                  <Button
                    className="defaultButton"
                    variant="primary"
                    onClick={() => navigate("../")}
                  >
                    Back
                  </Button>
                  <Button
                    className="defaultButton"
                    style={{ marginLeft: "2%" }}
                    variant="primary"
                    onClick={() => {
                      navigate("../createWorkshop");
                    }}
                  >
                    Create Workshop
                  </Button>
                </Form>
                <br></br>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ShowWorkshop;
