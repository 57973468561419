import { useNavigate } from "react-router-dom";
import {Row, Col, Button, Form} from 'react-bootstrap';
import {gql, useMutation} from '@apollo/client';
import { useState, useEffect } from "react";

const ADMIN_ACCOUNTS="John@lucidbard.com"  //replace with your admin emails

function importAll(r) {
    let images = [];
    r.keys().map((item, index) => (images.push(item.replace('./', ''))));
    return images;
  }
  
  const images = importAll(require.context('../Images/ProfilePictures', false, /\.(png|jpe?g|svg)$/));


const SIGNUP = gql`mutation SignUp($input: SignUpInput) {
    signUp(input: $input) {
      email
      firstName
      lastName
      password
      userType
    }
  }`
export default function RegisterPage() {
    const navigate = useNavigate();
    const [signup, {data}] = useMutation(SIGNUP);
    const [values, setValues] = useState({});
    const [matchError] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    useEffect( () => {
        async function tryMutation(){
            //this is exception handling sent from backend
            // Could create conditional out of entire useEffect function and add error to mutation obj after data. 
            // Not much use since either way we cannot catch multiple exceptions from backend
            // This needs to change on backend. If you want error handling exception via conditional see LoginPage.js
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            try{
                await signup({variables: {"input": {"username": values.username , "email": values.email, "firstName": values.firstName, "lastName": values.lastName, "password": values.password, userType: values.userType, "profileImg": generatePfp()}}})
            }catch(e)
            {
                const errors = {};
                matchError.message = e.message;
                if (matchError.message.includes("Username")){
                    errors.username = values.username;
                }   
                if (matchError.message.includes("Email"))
                {
                    errors.email = values.email;
                }
                setFormErrors(errors);
                setIsSubmit(false);
            }
        }}
        tryMutation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formErrors]);
    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        // would need to check which text box is changing to make this
        //setFormErrors({});
        setValues({...values, [name]: value });
    }
    const onChangeEmail = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        delete formErrors.email;
        const userType = ADMIN_ACCOUNTS.split(",").map(str => str.toLowerCase()).includes(value.toLowerCase()) ? 'admin' : 'user'; 
        setValues({...values, [name]: value, userType: userType});       
    }
    const onChangeUser = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        delete formErrors.username;
        setValues({...values, [name]: value });
    }
    

    // function validate(){
    //     setMatchError({...matchError, ["message"]: ""})
    //     if(values.password != values.confirmPass){
    //         setMatchError({...matchError, ["message"]: "Passwords do not match"})
    //         return false;
    //     }
    //     return true;
    // }

    const validateT = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/i;
        if (!values.username) {
            errors.username = "Username is required!";
        }
        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email!";
        }
        if (!values.firstName) {
            errors.firstName = "First name is required!";
        }
        if (!values.lastName) {
            errors.lastName = "Last name is required!";
        }
        if (!values.password) {
            errors.password = "Password is required!";
        } else if (!passRegex.test(values.password)) {
            errors.password = "Your password requires a minimum of eight characters, at least one uppercase letter, one lowercase letter and one number";
        }
        if (values.password !== values.confirmPass) {
            errors.confirmPass = "Passwords do not match!";
        }
        return errors;
    };

    const onSubmit = (e) =>{
        e.preventDefault();
        setFormErrors(validateT(values));
        setIsSubmit(true);
        // if(validate()){
        //     signup({variables: {"input": {"username": values.username , "email": values.email, "firstName": values.firstName, "lastName": values.lastName, "password": values.password}}})
        //     navigate('../verify')
        // }
    }

    
    
    if(data) {
        localStorage.setItem("user_email", values.email)
        navigate("../verify")
    }

    const generatePfp = () =>{
        const hexCode = Math.floor(Math.random()*16777215).toString(16);
        const randomFace = images[Math.floor(Math.random() * images.length)]
        return ("#" + hexCode + " " + randomFace)
    }
    return (
        <Row className='defaultPage SignLoginMainRow'>
            <Col style={{display:'flex', justifyContent:'center', alignItems:'center', width:'50%', backgroundColor:"rgba(17, 22, 35, 0)"}}>
                <Row>
                    <Col style={{backgroundColor:"rgba(9,9,9,0.6)",padding:'50px', borderRadius:'25px', color: "white"}}>
                        
                        <Row>
                        <Row><Col><h1><b>Welcome to RealityFlow</b></h1></Col></Row>
                        
                        <br></br>
                        <Row><Col><h3><i>Sign up</i></h3></Col></Row>
                            <Col>
                                <Form onSubmit={onSubmit}>
                                <Form.Group controlId="formBasicUsername">
                                        <Form.Label>Username</Form.Label>
                                        <Form.Control className={formErrors.username ? "registerFormError" : ""} type="username" name = "username" placeholder="Username" onChange = {onChangeUser}/>
                                    </Form.Group>
                                    <Form.Text style={{color:"lightgray"}}>
                                        Choose carefully, you can't change this.
                                        </Form.Text>
                                        <p>{ formErrors.username }</p>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control className={formErrors.email ? "registerFormError" : ""} type="email" placeholder="Enter email" name="email" onChange = {onChangeEmail}/>
                                        <Form.Text style={{color:"lightgray"}}>
                                        We'll never share your email with anyone else.
                                        </Form.Text>
                                    </Form.Group>
                                    <p>{ formErrors.email }</p>
                                    <br></br>
                                    <Form.Group controlId="formBasicUsername">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control className={formErrors.firstName ? "registerFormError" : ""} type="username" name = "firstName" placeholder="First Name" onChange = {onChange}/>
                                    </Form.Group>
                                    <p>{ formErrors.firstName }</p>
                                    <br></br>
                                    <Form.Group controlId="formBasicUsername">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control className={formErrors.lastName ? "registerFormError" : ""} type="username" name = "lastName" placeholder="Last Name" onChange = {onChange}/>
                                    </Form.Group>
                                    <p>{ formErrors.lastName }</p>
                                    <br></br>
                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control className={formErrors.password ? "registerFormError" : ""} type="password" placeholder="Password" name="password" onChange = {onChange}/>
                                    </Form.Group>
                                    <p>{ formErrors.password }</p>
                                    <br></br>
                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control type="password" placeholder="Password" name="confirmPass" onChange = {onChange}/>
                                    </Form.Group>
                                    <p>{ formErrors.confirmPass }</p>
                                    <div style = {{color: "red" }}>{matchError.message}</div>
                                    <br></br>
                                    
                                    <Button className = "defaultButton" variant="primary" type="submit">
                                        Sign up
                                    </Button>
                                </Form>
                                
                                <br></br>
                                <Row><Col><a style={{fontSize:'16px'}} href="../login">Have an account already? Sign in</a></Col></Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
