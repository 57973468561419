
import {Row, Col, Button} from 'react-bootstrap';


export default function EmailVerified() {
    
    return (
        <>
        <Row className='defaultPage SignLoginMainRow'>
            <Col style={{display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:"rgba(17, 22, 35, 0)"}}>
                <Row>
                    <Col style={{backgroundColor:"rgba(9,9,9,0.6)",padding:'50px', borderRadius:'25px', color: "white", maxWidth: "500px"}}>
                        
                        <Row>
                        <Row><Col><h1><b>Welcome to RealityFlow</b></h1></Col></Row>
                        <br></br>
                        <Row><Col><h4>Account Activated</h4></Col></Row>
                        <br></br>
                        <Row><Col><p>Thank you, your email has been verified. Your account is now active. Please use the link below to login to your account.</p></Col></Row>
                        <br></br>
                        <Col style={{justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
                            <Button href="/homepage" className = "defaultButton" variant="primary" type="submit">
                                    Login to your Account
                            </Button>
                        </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
        </>
    )
}