import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const UserProjectsDisplay = ({colCount, children, md, rowLimit = -1}) => {

    let rowCount = Math.floor(children.length / colCount) + 1;

   //let index = 0;

    const renderRows = () => {
        let rows = []
        
        for(let row = 0; row < rowCount && (rowLimit == -1 || row < rowLimit); row++) {
            rows.push(
                <Row key={row}>
                    {
                        renderCols(row)
                    }
                </Row>
            )
        }
        
        return rows
    }

    const renderCols = (r) => {
        let cols = []
        //rowAdd is the amount we add to col to get the current index of the child element
        var rowAdd = r*colCount;
        for(let col = 0; col < colCount; col++) {
            //If there is a project to add, add it
            const childIndex = col+rowAdd;
            if(childIndex < children.length){
                cols.push(
                    <Col key={`col}-${childIndex}`}>
                        {children[childIndex]}
                    </Col>
                )
            }
            else{
                //if no project exists, we still need an empty col tag to space them out correctly.
                cols.push(
                    <Col key={`empty-${col}-${r}`}></Col>
                )
            }
            //Previous way of doing projects display, too much unnnecessary math
            /*if (index === (children.length - 1) && ((index + 1) % colCount) !== 0)
            {
                
                cols.push(
                        <Col>
                        {children[index]}
                        </Col>

                )
                for (let i = 0; i < ((index+1) % colCount+1); i++) 
                {
                    cols.push(<Col></Col>)
                }
                index++
            }
            else if(index < children.length) 
            {
                cols.push(
                    <Col>
                        {children[index]}
                    </Col>
                )
                index++
            }*/
        }
        
        return cols
    }



    return (
        <>
        {children.length > 0 ? 
            renderRows() : null
        }
        </>
    );


};
export default UserProjectsDisplay;