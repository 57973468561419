import { gql, useMutation } from '@apollo/client';
import React, { useState, useEffect, useRef } from 'react';
import { Cookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import Renderer, { setModels, getGL, getScreenshot } from './Renderer/Renderer.js';
import { createGLB, createModel } from './Renderer/FileUtils/GLBParser.js';
import LoadingSpinner from './LoadingSpinner.js';
import { headerWithToken } from './Helpers/utils.js';


const OAuthToken = "513362254320-95fanvca0lslm2chg9sv91ku4eibqke7.apps.googleusercontent.com";
var accessToken;



/* File reading componet */
const ModelSelector = ({filesRef, onClose, onNext}) => {
  const [rerender, setRerender] = useState(false);

  const readFiles = async (rawFiles) => {
    const promises = [];
    for (const file of rawFiles) {
      promises.push(new Promise((res, rej) => {
        const reader = new FileReader();
        reader.onload = e => res({ name: file.name, content: e.target.result});
        reader.onerror = e => rej(e);
        reader.readAsArrayBuffer(file);
      }));
    }

    filesRef.current = await Promise.all(promises);
    setRerender(!rerender);
  };

  const handleFileSelect = async (e) => {
    readFiles(e.target.files);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    readFiles(e.dataTransfer.files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  
  return (
    <div
    className="GoogleDrivePicker-widget"
    onDrop={handleDrop}
    onDragOver={handleDragOver}
    >
      <div className="widget-header">
          <h2>Upload Model</h2>
          <button className="close-btn" onClick={onClose}>X</button>
      </div>
      
      <label htmlFor="model-selector" style={{
        borderStyle: "dashed", borderColor: "darkslateblue",
        borderWidth: "2px", borderRadius: "10px", width: "100%",
        minHeight: "100px", alignContent: "center", margin: "4% auto"
      }}>
        Drag a file over or click!
        {filesRef.current.length > 0 && (
          <ul style={{color: "darkcyan", textAlign: "left"}}>
            {filesRef.current.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
        )}
      </label>
      <input id="model-selector" type="file" style={{
        display: "none", userSelect: "none"}} onChange = {handleFileSelect}
        accept=".glb" multiple="multiple"/>
      
      <div className="upload-button-container">
        {filesRef.current.length > 0 ? <button className="upload-btn" 
          onClick={onNext}>Next</button> : null}
      </div>
    </div>
  );
};

/* File parsing componet */
const ModelProcessor = ({filesRef, onClose, onNext}) => {

  const [modelLoading, setModelLoading] = useState(true);
  const [currentFile, setCurrentFile] = useState(0);
  const [modelTitleValue, setModelTitleValue] = useState("");

  let modelTitleRef = useRef(0);

  useEffect(_ => {
    /* Queue model loading */
    let gl = getGL().current;
    for (const file of filesRef.current) {
      file.model = new Promise((res, rej) => {
        createGLB(file.content).then(glb => {
          file.glb = glb;
          return createModel(gl, glb);
        }).then(model => {
          file.triangles = 0;
          if (model)
            for (const mesh of model.meshes)
              for (const primitive of mesh.primitives)
                file.triangles += primitive.vertices / 3;

          res(model);
        })
      });
    }
  }, []);

  useEffect(_ => {
    const file = filesRef.current[currentFile];
    if (file == undefined)
      return;

    let name = file.name;
    let lastPeriod = name.length - 1;
    while (lastPeriod != -1 && name[lastPeriod] != '.')
      --lastPeriod;
    
    setModels([]);
    setModelLoading(true);
    file.model.then(model => {
      if (model == null) {
        alert("Failed to load this model");
        setModels([]);
      } else {
        setModels([model]);
      }
      setModelLoading(false);
    });


    modelTitleRef.current.value = (lastPeriod != -1 ? name.substring(0, lastPeriod) : name)
      .replaceAll('-', ' ').replaceAll('_', ' ');
    setModelTitleValue(modelTitleRef.current.value);
  }, [currentFile]);


  const gotoNext = _ => {
    // Also maybe have a cancel this specific model button
    const file = filesRef.current[currentFile];
    file.title = modelTitleValue.length > 0 ? modelTitleValue : file.name;
    file.thumbnail = getScreenshot();

    if (currentFile + 1 == filesRef.current.length)
      onNext();
    setCurrentFile(currentFile + 1);
  }


  return (
    <div className="GoogleDrivePicker-widget"
        style={{display: "flex", flexDirection: "column"}}>
      <div className="widget-header">
        <button className="close-btn" onClick={onClose}>X</button>
      </div>
      
      <input style={{fontSize: "24", userSelect: "none"}} type="text"
        ref={modelTitleRef} placeholder="Title"
        onChange={e => setModelTitleValue(e.target.value)} />

      <h6 style={{
        width: "300px", marginTop: "4%", color: "lightgray", userSelect: "none"
      }}>Position the model for its thumbnail by using your mouse.</h6>
      <Renderer height="300px" width="300px" style={{marginBottom: "4%"}} />
      {modelLoading ? <h3>'Model loading...'</h3> : null}
      
      <input type="button" value="Upload" onClick={gotoNext} />
    </div>
  )
};


const CREATE = gql`
mutation Create($input: CreateModelInput!) {
  createModel(input: $input) {
    name
  }
}`;
const GoogleDrive = ({ filesRef, onClose }) => {
  const [gisInited, setGisInited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({});
  const [create] = useMutation(CREATE);
  const cookies = new Cookies();
  const accountToken = cookies.get("accessToken");
  const navigate = useNavigate();

  useEffect(() => {
    if (accountToken == null) {
      navigate('/');
    }
  }, [accountToken]);

  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = 'https://apis.google.com/js/api.js';
    script1.async = true;
    script1.defer = true;
    // script1.onload = onApiLoad;
    document.body.appendChild(script1);

    const script2 = document.createElement('script');
    script2.src = 'https://accounts.google.com/gsi/client';
    script2.async = true;
    script2.defer = true;
    script2.onload = gisLoaded;
    document.body.appendChild(script2);

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);


  const gisLoaded = () => {
    // Initialize tokenClient and setGisInited to true
    window.tokenClient = window.google.accounts.oauth2.initTokenClient({
      client_id: OAuthToken,
      scope: 'https://www.googleapis.com/auth/drive.file',
      callback: '', // defined later
    });
    setGisInited(true);
  };

  useEffect(_ => {
    if (!gisInited)
      return;
    
    uploadFilesToDrive(filesRef.current);
  }, [gisInited]);

  const findOrCreateFolder = async () => {
    try {
      // Search for the 'RealityFlow' folder
      const response = await fetch('https://www.googleapis.com/drive/v3/files?q=name="RealityFlow" and mimeType="application/vnd.google-apps.folder"&spaces=drive&fields=files(id,name)', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error searching for folder: ' + response.statusText);
      }

      const data = await response.json();
      console.log('Search result:', data);

      if (data.files.length > 0) {
        // Folder exists, return its ID
        return data.files[0].id;
      } else {
        // Folder does not exist, create it
        const createResponse = await fetch('https://www.googleapis.com/drive/v3/files', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: 'RealityFlow',
            mimeType: 'application/vnd.google-apps.folder',
          }),
        });

        if (!createResponse.ok) {
          throw new Error('Error creating folder: ' + createResponse.statusText);
        }

        const createData = await createResponse.json();
        console.log('Folder created:', createData);

        return createData.id;
      }
    } catch (error) {
      console.error('Error in findOrCreateFolder:', error);
      throw error;
    }
  };


  /* Google Drive API */
  async function uploadFilesToDrive(files) {
    // Check if gisInited is true before proceeding
    setIsLoading(true);

    if (!gisInited) return false;

    // Request an access token.
    window.tokenClient.callback = async (response) => {
      if (response.error !== undefined) {
        throw (response);
      }
      accessToken = response.access_token;
      console.log(accessToken);

      const folderId = await findOrCreateFolder();

      const urls = [];
      const promises = [];
      for (const file of files) {
        promises.push(new Promise(async (res, rej) => {
          try {
            // Create file metadata including the parent folder ID
            const metadata = {
              name: file.title,
              parents: [folderId],
            };

            // Convert metadata to a JSON string and then to a Blob
            const metadataBlob = new Blob([JSON.stringify(metadata)], { type: 'application/json' });

            // Create a FormData object and append the metadata and file content
            const formData = new FormData();
            formData.append('metadata', metadataBlob);
            formData.append('file', new Blob([file.content], { type: file.type }));

            // Use the multipart upload endpoint to include metadata
            const uploadResponse = await fetch(`https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart`, {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              body: formData,
            });

            if (!uploadResponse.ok) {
              throw new Error('Failed to upload file to Drive');
            }

            const uploadData = await uploadResponse.json();
            const fileId = uploadData.id;

            // Set file permissions to allow public access
            const permissionResponse = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}/permissions`, {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                role: 'reader',
                type: 'anyone',
              }),
            });

            if (!permissionResponse.ok) {
              throw new Error('Failed to set file permissions');
            }

            // Get public URL of the file
            const publicUrl = `https://drive.google.com/uc?id=${fileId}`;

            console.log('File uploaded successfully');
            console.log('Public URL:', publicUrl);

            // Here you can use 'publicUrl' as needed, such as displaying it in the UI
            setUploadStatus((prevStatus) => ({
              ...prevStatus,
              [file.title]: 'success'
            }));          

            urls.push(publicUrl);
          } catch (error) {
            console.error('Error uploading file to Drive:', error);
            
            setUploadStatus((prevStatus) => ({
              ...prevStatus,
              [file.title]: 'failed'
            }));
            urls.push(null);
          }
          res();
        }));
      }
    
      await Promise.all(promises);
      /* Add models to the database */
      for (const i in urls) {
        if (urls[i] == null)
          continue;
        const file = filesRef.current[i];
        
        create({
          variables: {
            input: {
              name: file.title, triangles: file.triangles,
              downloadURL: urls[i], thumbnailURL: file.thumbnail 
            }
          },
          context: headerWithToken(accountToken)
        });
      }

      setIsLoading(false);
    };

    if (accessToken === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      window.tokenClient.requestAccessToken({prompt: 'consent'});
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      window.tokenClient.requestAccessToken({prompt: ''});
    }
  };


  // make sure to set files to [] after await is done,
  // also maybe have the X button abort any in progress parts

  return (
    <div className="GoogleDrivePicker-widget">
      <div className="widget-header">
        <button className="close-btn" onClick={onClose}>X</button>
      </div>

      <ul style={{ color: "darkcyan", textAlign: "left" }}>
        {filesRef.current.map((file, index) => (
          <li
            key={index}
            style={{ color: uploadStatus[file.title] === 'success' ? 'green'
              : uploadStatus[file.title] === 'failed' ? 'red' : 'orange' }}
          >
            {file.title} - {uploadStatus[file.title] || 'Uploading...'}
          </li>
        ))}
      </ul>

      {isLoading ? <LoadingSpinner style={{marginTop: "12px"}} /> : null}
    </div>
  );
};


/* Combined Componet */
const ModelUploader = ({ onClose }) => {
  const filesRef = useRef([]);
  const [phase, setPhase] = useState(0);

  const moveToProcessPhase = _ => {
    if (filesRef.current.length == 0) {
      onClose();
      return;
    }
    setPhase(1);
  }

  const moveToUploadPhase = _ => {
    setPhase(2);
  }

  const close = _ => {
    filesRef.current.length = 0;
    setPhase(0);
    onClose();
  }

  // Click outside to close
  useEffect(_ => {
    document.addEventListener("mousedown", event => {
      const widget = document.querySelector('.GoogleDrivePicker-widget');
      if (widget && !widget.contains(event.target)) {
        close();
      }
    })
  }, []);



  return (
    <div className="GoogleDrivePicker-widget-overlay">
      {
        phase == 0 ? <ModelSelector filesRef={filesRef} onClose={close}
            onNext={moveToProcessPhase} />
          : phase == 1 ? <ModelProcessor filesRef={filesRef} onClose={close}
            onNext={moveToUploadPhase} />
          : <GoogleDrive filesRef={filesRef} onClose={close} />
      }
    </div>
  );
};

export default ModelUploader;
