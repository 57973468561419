import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import chatImage from '../Images/chatImg.png'
import accountImage from '../Images/accountImg.png'
import { useNavigate } from "react-router-dom";
import { Cookies } from 'react-cookie';
import FriendDisplay from './FriendComponents/FriendDisplay';
import { useState } from 'react';
import { gql, useQuery} from '@apollo/client';
import { headerWithToken } from './Helpers/utils';

const GETACCOUNT = gql`query GetUserById($getUserByIdId: String!) {
  getUserById(id: $getUserByIdId) {
    userType
    workshopsInstruct {
      id
      name
    }
    workshopsOwn {
      id
      name
    }
  }
}`


function RfNavbar() {
  const navigate = useNavigate();
  var user_data = null;
  const cookies = new Cookies();
  const accesstoken = cookies.get("accessToken")
  if(accesstoken) user_data = cookies.get("userData")
  const [searchData, setSearchData] = useState();
  const logOut = () =>{
    cookies.remove("accessToken")
    cookies.remove("userData")
    navigate('../')
  }
  const {data} = useQuery(GETACCOUNT, {variables: { "getUserByIdId": user_data?.id }, context: headerWithToken(accesstoken)});

  const handleSearchChange = (e) =>{
    setSearchData(e.target.value)
  }

  const handleSearchSubmit = (e) =>{
    e.preventDefault();
    if(searchData) { 
      navigate(`../search?s=${searchData}`)
      window.location.reload(false);
    }
  }
  return (
    <Navbar className = 'RfNavbar prevent-select' expand="lg">
      <Container fluid>
        <Navbar.Brand className="ms-lg-4" href="./" style={{ color: "white"}}>Reality Flow </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-auto my-lg-0"
            style={{ maxHeight: '40vh' }}
            navbarScroll
          >
            {user_data ? <Nav.Link href="./discovery" style = {{color: 'white'}}>Discovery</Nav.Link> : null}
            {user_data ? <Nav.Link href="../myprojects" style = {{color: 'white'}}>My Projects</Nav.Link> : null}
            {(data && data.getUserById.userType === 'admin') || (data && (data.getUserById.workshopsOwn?.length > 0 || data.getUserById.workshopsInstruct?.length > 0)) ? <Nav.Link href="../showWorkshop" style = {{color: 'white'}}>{'Workshops'}</Nav.Link> : null}
          </Nav>
         {user_data ? <Form className="d-inline-flex w-50" onSubmit = {handleSearchSubmit}>
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              style = {{background: '#505050', color: 'white'}}
              onChange = {handleSearchChange}
              
            />
            <Button variant="outline-success" style = {{color:'white', borderColor: 'white'}} onClick = {handleSearchSubmit}>Search</Button>
          </Form> : null}

         {user_data ? <Nav
            className="me-auto me-lg-0 ms-auto my-auto my-lg-0"
            style={{ maxHeight: '40vh'}}
            navbarScroll
          ><NavDropdown title = {
                        <img className="thumbnail-image" 
                            src = {chatImage}
                            alt = 'Chat Bubble'
                            style = {{height: '37px'}}
                        />
                    } id="navbarScrollingDropdown" align = "end">
            
            <FriendDisplay/>
            </NavDropdown>
            <NavDropdown className="me-lg-4" title={
                <img src = {accountImage}
                    alt = "Account Link"
                    style = {{height: '37px'}}
                    />
                } id="navbarScrollingDropdown" align = "end">
              <NavDropdown.Item href={`/account?acc=${user_data.id}`}>Your Account</NavDropdown.Item>
              <NavDropdown.Item href="/settingpage">
                Settings
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/otpcode">
                VR OTP Code
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick = {logOut}>
                Logout
              </NavDropdown.Item>
            </NavDropdown> </Nav>: (<><Button href='/login' className='loginButton'>Login</Button> <Button href="/register" className='signupButton'>Sign Up</Button></>)}
            
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default RfNavbar;