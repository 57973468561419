import React, { useState, useEffect } from 'react';
import LoadingSpinner from './LoadingSpinner';

const OAuthToken = "513362254320-95fanvca0lslm2chg9sv91ku4eibqke7.apps.googleusercontent.com";
var accessToken;

const GoogleDrivePicker = ({ onClose }) => {
  const [pickerInited, setPickerInited] = useState(false);
  const [gisInited, setGisInited] = useState(false);
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({});

  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = 'https://apis.google.com/js/api.js';
    script1.async = true;
    script1.defer = true;
    script1.onload = onApiLoad;
    document.body.appendChild(script1);

    const script2 = document.createElement('script');
    script2.src = 'https://accounts.google.com/gsi/client';
    script2.async = true;
    script2.defer = true;
    script2.onload = gisLoaded;
    document.body.appendChild(script2);

    const handleClickOutside = (event) => {
      const widget = document.querySelector('.GoogleDrivePicker-widget');
      if (widget && !widget.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const onApiLoad = () => {
    window.gapi.load('picker', onPickerApiLoad);
  };

  const onPickerApiLoad = () => {
    setPickerInited(true);
  };

  const pickerCallback = (data) => {
    let url = 'nothing';
    if (data[window.google.picker.Response.ACTION] === window.google.picker.Action.PICKED) {
      let doc = data[window.google.picker.Response.DOCUMENTS][0];
      url = doc[window.google.picker.Document.URL];
    }
    let message = `You picked: ${url}`;
    document.getElementById('result').innerText = message;
  };

  const gisLoaded = () => {
    // Initialize tokenClient and setGisInited to true
    window.tokenClient = window.google.accounts.oauth2.initTokenClient({
      client_id: OAuthToken,
      scope: 'https://www.googleapis.com/auth/drive.file',
      callback: '', // defined later
    });
    setGisInited(true);
  };

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;

    // Read file content for each dropped file
    const fileDataPromises = Array.from(files).map(async (file) => {
      const fileContent = await readFileContent(file);
      return { name: file.name, content: fileContent };
    });

    // Wait for all file data to be read
    const fileDataArray = await Promise.all(fileDataPromises);

    // Set dropped files state with file data
    setDroppedFiles(fileDataArray);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const findOrCreateFolder = async () => {
    try {
      // Search for the 'RealityFlow' folder
      const response = await fetch('https://www.googleapis.com/drive/v3/files?q=name="RealityFlow" and mimeType="application/vnd.google-apps.folder"&spaces=drive&fields=files(id,name)', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error searching for folder: ' + response.statusText);
      }

      const data = await response.json();
      console.log('Search result:', data);

      if (data.files.length > 0) {
        // Folder exists, return its ID
        return data.files[0].id;
      } else {
        // Folder does not exist, create it
        const createResponse = await fetch('https://www.googleapis.com/drive/v3/files', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: 'RealityFlow',
            mimeType: 'application/vnd.google-apps.folder',
          }),
        });

        if (!createResponse.ok) {
          throw new Error('Error creating folder: ' + createResponse.statusText);
        }

        const createData = await createResponse.json();
        console.log('Folder created:', createData);

        return createData.id;
      }
    } catch (error) {
      console.error('Error in findOrCreateFolder:', error);
      throw error;
    }
  };

  const uploadFileToDrive = async (droppedFiles) => {
    //loading status
    setIsLoading(true);
    const status = {};

    // Check if gisInited is true before proceeding
    if (!gisInited) return;

    // Request an access token.
    window.tokenClient.callback = async (response) => {
      if (response.error !== undefined) {
        throw response;
      }
      accessToken = response.access_token;
      console.log(accessToken);

      // Get the folder ID where the files will be uploaded
      const folderId = await findOrCreateFolder();

      for (const file of droppedFiles) {

        try {
          // Create file metadata including the parent folder ID
          const metadata = {
            name: file.name,
            parents: [folderId],
          };

          // Convert metadata to a JSON string and then to a Blob
          const metadataBlob = new Blob([JSON.stringify(metadata)], { type: 'application/json' });

          // Create a FormData object and append the metadata and file content
          const formData = new FormData();
          formData.append('metadata', metadataBlob);
          formData.append('file', new Blob([file.content], { type: file.type }));

          // Use the multipart upload endpoint to include metadata
          const uploadResponse = await fetch(`https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            body: formData,
          });

          if (!uploadResponse.ok) {
            throw new Error('Failed to upload file to Drive');
          }

          const uploadData = await uploadResponse.json();
          const fileId = uploadData.id;

          // Set file permissions to allow public access
          const permissionResponse = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}/permissions`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              role: 'reader',
              type: 'anyone',
            }),
          });

          if (!permissionResponse.ok) {
            throw new Error('Failed to set file permissions');
          }

          // Get public URL of the file
          const publicUrl = `https://drive.google.com/uc?id=${fileId}`;

          console.log('File uploaded successfully');
          console.log('Public URL:', publicUrl);

          setUploadStatus((prevStatus) => ({
            ...prevStatus,
            [file.name]: 'success'
          }));

          // Here you can use 'publicUrl' as needed, such as displaying it in the UI
        } catch (error) {
          console.error('Error uploading file to Drive:', error);
          setUploadStatus((prevStatus) => ({
            ...prevStatus,
            [file.name]: 'failed'
          }));

        }
      }
      setIsLoading(false);
    };

    if (accessToken === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      window.tokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      window.tokenClient.requestAccessToken({ prompt: '' });
    }
  };

  const handleFileSelect = async (e) => {
    const files = e.target.files;

    // Read file content for each selected file
    const fileDataPromises = Array.from(files).map(async (file) => {
      const fileContent = await readFileContent(file);
      return { name: file.name, content: fileContent };
    });

    // Wait for all file data to be read
    const fileDataArray = await Promise.all(fileDataPromises);

    // Set selected files state with file data
    setDroppedFiles(fileDataArray);
  };

  return (
    <div className="GoogleDrivePicker-widget-overlay">
      <div
        className="GoogleDrivePicker-widget"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <div className="widget-header">
          <h2>Upload Model</h2>
          <button className="close-btn" onClick={onClose}>X</button>
        </div>
        {isLoading ? (
          <div style={{ marginTop: '15px' }}>
            <LoadingSpinner />
            <ul style={{ color: "darkcyan", textAlign: "left" }}>
              {droppedFiles.map((file, index) => (
                <li
                  key={index}
                  style={{ color: uploadStatus[file.name] === 'success' ? 'green' : uploadStatus[file.name] === 'failed' ? 'red' : 'orange' }}
                >
                  {file.name} - {uploadStatus[file.name] || 'Uploading...'}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div>
            {Object.keys(uploadStatus).length === 0 ? (
              <div>
                <label
                  htmlFor="model-selector"
                  style={{
                    borderStyle: "dashed",
                    borderColor: "darkslateblue",
                    borderWidth: "2px",
                    borderRadius: "10px",
                    width: "100%",
                    height: "100px",
                    alignContent: "center",
                    margin: "4% auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {droppedFiles.length > 0 ? (
                    <ul style={{ color: "darkcyan", textAlign: "left" }}>
                      {droppedFiles.map((file, index) => (
                        <li key={index}>{file.name}</li>
                      ))}
                    </ul>
                  ) : (
                    <div>Drag a file over or click! <br />
                      .obj, .fbx or .GLTF
                    </div>
                  )}
                </label>
                <input
                  id="model-selector"
                  type="file"
                  style={{ display: "none", userSelect: "none" }}
                  onChange={handleFileSelect}
                  accept=".obj,.fbx,.gltf"
                  multiple
                />
                <div className="upload-button-container">
                  {droppedFiles.length > 0 && (
                    <button className="upload-btn" onClick={() => uploadFileToDrive(droppedFiles)}>
                      Upload
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <ul style={{ color: "darkcyan", textAlign: "left" }}>
                {droppedFiles.map((file, index) => (
                  <li
                    key={index}
                    style={{ color: uploadStatus[file.name] === 'success' ? 'green' : uploadStatus[file.name] === 'failed' ? 'red' : 'orange' }}
                  >
                    {file.name} - {uploadStatus[file.name]}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default GoogleDrivePicker;
