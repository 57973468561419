export const vert_source = `
precision highp float;

attribute vec4 a_vertex;
attribute vec3 a_normal;
attribute vec2 a_texture_position;

uniform mat4 u_proj;
uniform mat4 u_mesh;
uniform float u_view[6]; // x y z x_pitch y_pitch
uniform float u_model[6]; // x y z x_pitch y_pitch z_pitch
uniform vec4 u_color;

varying vec2 p_texture_position;
varying vec4 p_color;

varying vec3 p_normal;
varying vec3 p_camera_position;
varying vec3 p_model_position;

#define xi	0
#define yi	1
#define zi	2
#define xpi	3
#define ypi	4


mat4
getMatrix(float state[6])
{
	float cx = cos(state[3]);
	float sx = sin(state[3]);
	float cy = cos(state[4]);
	float sy = sin(state[4]);
	float cz = cos(state[5]);
	float sz = sin(state[5]);

	// TODO z pitch, properly multiply translation
	return
		mat4(
			cy,		sx * sy,	-cx * sy,	0.,
			0.,		cx,			sx,			0.,
			sy,		-sx * cy,	cx * cy,	0.,
			0.,		0.,			0.,			1.
		)
		*
		mat4(
			1.0,		0.0,		0.0,		0.0,
			0.0,		1.0,		0.0,		0.0,
			0.0,		0.0,		1.0,		0.0,
			state[0],	state[1],	state[2],	1.0
		);
}

void
main()
{
	p_texture_position = a_texture_position;
	p_color = u_color;

	// Apply the mesh transform onto the normals, but remove the
	// translation aspect of the matrix for this
	mat4 u_mesh_no_trans = u_mesh;
	u_mesh_no_trans[3][0] = 0.0;
	u_mesh_no_trans[3][1] = 0.0;
	u_mesh_no_trans[3][2] = 0.0;
	p_normal = (u_mesh_no_trans * vec4(a_normal, 1.0)).xyz;

	// Create matrices for positioning
	mat4 camera_mat = getMatrix(u_view);
	mat4 model_mat = getMatrix(u_model);

	// Pass more shading info
	p_camera_position = vec3(u_view[xi], u_view[yi], u_view[zi]);
	p_model_position = a_vertex.xyz;

	// Calculate position
	gl_Position = u_proj * camera_mat * model_mat * u_mesh * a_vertex;
}
`;

export const frag_source = `
precision highp float;

varying vec2 p_texture_position;
varying vec4 p_color;

varying vec3 p_normal;
varying vec3 p_camera_position;
varying vec3 p_model_position;

uniform bool u_use_texture;
uniform sampler2D sampler;

const vec3 sun = vec3(0, 1, 2);

void
main()
{
	vec3 unit_normal = normalize(p_normal);
	float sun_factor = .6 * dot(unit_normal, normalize(sun));
	if (sun_factor < .0)
		sun_factor = .0;

	gl_FragColor = u_use_texture ?
		texture2D(sampler, p_texture_position) : p_color;
	if (gl_FragColor.a != .0)
		gl_FragColor.xyz *= (.3 + sun_factor);
	else
		discard;
}
`;
