function importAll(r) {
    let images = {};
    r.keys().map((item, index) =>  images[item.replace('./', '')] = r(item));
    return images;
  }
  
  const images = importAll(require.context('../Images/ProfilePictures', false, /\.(png|jpe?g|svg)$/));
  

const ProfilePicture = ({profileImg, useClass}) => {
    const pfpElements = profileImg.split(" ")
    
    return( 
        <img src={images[pfpElements[1]]} alt="profile" style={{backgroundColor: pfpElements[0]}} className = {useClass}></img>
    )

}
export default ProfilePicture;