import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {gql, useQuery} from '@apollo/client'
import { ListGroupItem } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';
import { useEffect } from 'react';
import { headerWithToken } from '../Helpers/utils';
import  FriendAccept  from './FriendAccept'
import FriendReject from './FriendReject';
import ProfilePicture from '../ProfilePicture';
import NavDropdown from 'react-bootstrap/NavDropdown';

  

const GETREQUESTS = gql`query GetFriendRequest($userId: String!) {
    getFriendRequest(userId: $userId) {
      from {
        id
        username
        profileImg
      }
    }
  }`

const FriendRequestDisplay = ({renderObj, userId, accesstoken}) => {
    
    const {data, loading, refetch} = useQuery(GETREQUESTS, {variables: {"userId" : userId}, context: headerWithToken(accesstoken)})

    useEffect(()=>{
        refetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderObj.isRender])
    if(loading){
        return (
            <div>Loading...</div>
        )
    }
    return (
        <ListGroup style={{maxHeight: '60vh', overflowY: "scroll"}}>
                  {data.getFriendRequest.map((friend) => (
                    <NavDropdown.Item className='friendItem' href={`/account?acc=${friend.from.id}`} style={{textDecoration: 'none',  border: 'solid 1px white', borderRadius:'5px', marginTop:'3%'}}>
                      <ListGroupItem className='friendItem' style={{background:'transparent', border:'none', color:'white' }}>
                        
                          <Row style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <Col style={{paddingRight:'0', maxWidth:'33%'}}>
                            <ProfilePicture profileImg = {friend.from.profileImg} useClass = 'smallProfilePicSettings'/>     
                            </Col>
                            <Col className='text-truncate' style={{paddingLeft:'0'}}>
                            {friend.from.username}
                            </Col>
                            <Col style={{padding:'0', margin:'0'}}>
                                <Row style={{padding:'0', margin:'0'}}>
                                    <Col style={{display:'flex', alignItems:'center', justifyContent:'center', paddingRight:'5%'}}>
                                        <FriendAccept userId = {userId} friendId = {friend.from.id} accesstoken = {accesstoken} renderObj = {renderObj}/>
                                    </Col>
                                    <Col style={{display:'flex', alignItems:'center', justifyContent:'center', paddingLeft:'5%', paddingRight:'0'}}>
                                        <FriendReject userId = {userId} friendId = {friend.from.id} accesstoken = {accesstoken} renderObj = {renderObj}/>
                                    </Col>
                                </Row>
                            </Col>
                          </Row>
                           
                      </ListGroupItem>
                      </NavDropdown.Item>
                  ))}
              </ListGroup>
    );


};
export default FriendRequestDisplay;



