import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RfNavbar from '../Components/Navbar'
import Button from 'react-bootstrap/Button';
import RfCarousel from '../Components/RfCarousel'
import {gql, useQuery, useMutation} from '@apollo/client'
import {useCookies, Cookies }from 'react-cookie';
import {headerWithToken, kickUser} from "../Components/Helpers/utils";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import AddUser from "../Components/AddUser";
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';
import {ChevronExpand as ExpandIcon, RocketTakeoff as LaunchIcon, ArrowClockwise as RefreshIcon} from 'react-bootstrap-icons';

const SAVECHANGES = gql`mutation SetDefaultProject($input: SetDefaultProjectInput) {
    setDefaultProject(input: $input) {
      defaultProjectId
      id
    }
  }`;

  const GETONLYPROJECTINFO = gql`
  query GetProjectById($id: String!) {
    getProjectById(id: $id) {
      id
      projectName
      createAt
      description
      details
      gallery
      isPublic
      publicUrl
      globalId
      projectOwner {
        username
        id
      }
      projectCoOwners {
        username
        id
      }
      rooms {
        id
        creatorId
      }
    }
  }
`;
  
  const GETPROJECTINFO = gql`query GetProjectById($getProjectByIdId: String!, $id: String!) {
    getProjectById(id: $getProjectByIdId) {
        id
      projectName
      createAt
      description
      details
      publicUrl
      gallery
      isPublic
      projectOwner{
        username
        id
      }
      projectCoOwners{
        username
        id
      }
      rooms{
        id
        creatorId
        }
    }
    getUserById(id: $id){
        id
        defaultProjectId
    }
  }`;
  
  const GET_ROOM_USERS = gql
  `query GetRoomUsers($input: GetRoomUsersInput, $id: String!) {
        getUserById(id: $id){
            id
            currentRoomId
        }
      getRoomUsers(input: $input) {
          id
      }
  }`;
  
  const SET_CURRENT_ROOM = gql`
  mutation Mutation($input: UpdateUserRoomIdInput){
    updateUserRoomId(input: $input){
        id
    }
}

  `
  // project 641c48b86e6f8cf935cd56e3
  // room 4c587f4f-6521-428c-952a-d65d538a8aa1
  // user 641c48026e6f8cf935cd56e0

export default function RoomDetailPage() {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const accesstoken = cookies.get("accessToken");
    const user_data = cookies.get("userData");
    const [, setCookie] = useCookies(['accessToken', 'userData']);
    const [refetchSignal, setRefetchSignal]= useState(false)

    if(accesstoken == null || user_data == null){
        console.log("NO ACCESSTOKEN");
        navigate('/')
    }

    const [open, setOpen] = useState(false);
    const queryParams = new URLSearchParams(window.location.search);
    const projectID = queryParams.get("p");
    const [project_data, setProjectData] = useState({});
    var moment = require('moment');

    const [show, setShow] = useState(false);


    const [saveChanges] = useMutation(SAVECHANGES);
    // should add ", [data, dataUser]" so the other calls arent necessary just useEffect() 
    //and then add logic of those functions to this. This will fix duplicate prints/calls
    const {data: data, loading, error, refetch} = useQuery(GETPROJECTINFO, {
        variables: {"getProjectByIdId": projectID, "id": user_data.id},
        context: headerWithToken(accesstoken)
    });
    const {data: remoteProject } = useQuery(GETONLYPROJECTINFO, { variables: {"id": projectID},
    context: headerWithToken(accesstoken)
    });
   
    useEffect(() => {

        if (!data && remoteProject){
            setProjectData(remoteProject.getProjectById);
        }else if (!loading && data){
            setProjectData(data.getProjectById);
        }
    },[loading, data]) //eslint-disable-line

    useEffect(()=>{
        refetch();
    },[refetchSignal]) //eslint-disable-line

    //console.log("DDDDDDDDDDAATAAadanfhsinbieujenkehbbA");
    //console.log(data);
    if(error) {
        if(error.message === 'Not Authenticated'){
            kickUser(navigate, cookies);
        }
    }
    if (loading)
    {
        return (<p>LOADING....</p>)
    }
      
      const checkOwn = () =>{
            if(!data) return false;
            if(user_data.id === project_data.projectOwner?.id) return true;
            for(var i = 0; i < project_data.projectCoOwners?.length ; i++){
                if(user_data.id === project_data.projectCoOwners[i]?.id) return true;
            }
            return false;
      }
      const defaultProjectShow = () => {
        if (data){
            if (data.getUserById?.defaultProjectId !== projectID){
                return (
                    <Row onClick={setDefaultProject} className='mt-2 mt-lg-5'>
                        <Button id='projectDefaultButton'>Set Default Project</Button>
                    </Row>);
            } 
            return(
                <Row className='mt-2 mt-lg-5'>
                    <Button id='projectDefaultButton' disabled>Already Default Project</Button>
                </Row>
            );
        }
      }
      const setDefaultProject = () => 
      {
        try{
            //console.log("DATA BEFORE" + JSON.stringify(user_data));
            //console.log("PROJECT ID: " + JSON.stringify(project_data.id));
            saveChanges({
                variables: {
                    "input": {
                        "projectId": project_data.id,
                        "userId": user_data.id
                    }
                },
                context: headerWithToken(accesstoken)})                
            user_data.defaultProjectId = project_data.id;
            setCookie('userData', data.getUserById, {path: '/'})
        }
        catch (excep)
        {
            console.error(excep.message);
        }
            //user_data = cookies.get("userData")
            //console.log("DATA AFTER" + JSON.stringify(user_data));
      }

      const showFirstThree = () => {
        return (
            <>
            {
                project_data?.rooms.slice(0,3).map((room) => <CardLayout theRoom={room} defaultProjectId = {projectID} refetchSignal={refetchSignal} setRefetchSignal={setRefetchSignal}></CardLayout>)}
            </>
        );
      }
      const showRooms = () => {
        return (
            <>
            {
                project_data?.rooms.slice(3).map((room) => <CardLayout theRoom={room} defaultProjectId = {projectID} refetchSignal={refetchSignal} setRefetchSignal={setRefetchSignal}></CardLayout>)}
            </>
        );
      }
    return (
        <>
        <RfNavbar></RfNavbar>
        <Row className="defaultPage p-auto pt-3 pb-3 ps-lg-3">
            
            <Col className='col-12 col-md-7 col-sm-12' style={{textAlign:'center', maxWidth:'100%'}}>
                <Row>
                    {((data || remoteProject) && project_data && project_data.gallery) ? (<RfCarousel images={project_data.gallery}></RfCarousel>):null}
                </Row>
                <Row className='mt-2'>
                    <Col className='col-12 col-lg-6'>by {(data || remoteProject) ? (project_data.projectOwner?.username ?? project_data.publicUrl) :  "Username"}</Col>
                    {checkOwn() ? <Col style={{textAlign:'right'}}><Button onClick={()=>setShow(true)}>Edit Permissions</Button> </Col> : <Col></Col>}
                </Row>
                <Modal show={show} onHide={()=>setShow(false)} >
                    <AddUser projectId={projectID}></AddUser>
                    <Button onClick={()=>setShow(false)}>Done</Button>
                </Modal>
                <Row className='mt-3'>
                    <Col className='col-12 col-lg-6'>
                        created {(data || remoteProject) ? moment(project_data.createAt).format("MMM Do YYYY") :"Jan 01 0000"}
                    </Col>
                    <Col></Col>
                </Row>
                <Row className='mt-3'>
                    <Col className='col-lg-6 col-12'>
                        {(data || remoteProject) ? (project_data.isPublic ? (<div>Public</div>) : (<div>Private</div>)) : "public"}
                    </Col>
                    <Col></Col>
                </Row>
                <Row className='mt-3'>
                    <Col className='mb-1 col-12 col-lg-6'>
                            Details
                    </Col>
                    <Col className='m-3 text-wrap'>
                        {(data || remoteProject) ? project_data.details : "Project Details"}
                    </Col>
                </Row>
            </Col>
            <Col className='m-3' style={{textAlign:'left'}}>
                <Row >
                    <Col><h1>{(data || remoteProject) ? project_data?.projectName : "Project Name"}</h1></Col>
                    {(checkOwn()) ? <Col style={{textAlign:'right'}}>
                        {/*Checks if user owns the project, then */}
                        <Link to={`../editproject?p=${projectID}`}><Button>Edit</Button></Link>
                    </Col> : null}
                </Row>
                <Row className='mb-1 mt-4'>Description</Row>
                <Row style={{overflow: "scrollable", border:"solid 1px white", borderRadius: "10px"}}>
                    <p>
                        {(data || remoteProject) ? project_data?.description : "Project Description"}
                    </p>
                </Row>
                {(data) ? defaultProjectShow() : null} 
                {/**/}
                { (project_data?.rooms && project_data?.rooms.length > 0) ? 
                <><Row className='mt-3' style={{backgroundColor:'grey', alignItems:'left'}}>
                    {(project_data?.rooms.length > 3) ? 
                    <>
                             <Button className='roomCollapseButton btn btn-dark mt-1 mb-1 ms-1'
                onClick={() => setOpen(!open)}
                aria-controls="room-parent"
                aria-expanded={open}
              >
                <ExpandIcon style={{fontSize:'2rem'}}></ExpandIcon>
        </Button> 

              </>
              : <Col className='col-2'></Col>}
              <Col style={{textAlign:'left'}}><h3 className='my-2'>Rooms</h3></Col>
              <Button className='roomCollapseButton btn btn-dark mt-1 mb-1 me-1'
              onClick={() => {setRefetchSignal(!refetchSignal);}}>
                <RefreshIcon style={{fontSize:'2rem'}} ></RefreshIcon>
                    </Button>
              </Row>
              <Row className={(!open) ? 'roomCardParent1' : 'roomCardParent2'}>
                {/*showFirstThree()*/}
                <Row className='roomCardParent1'>{showFirstThree()}</Row>
                <Collapse in={open} >
                    <Row className='roomCardParent1' id='room-parent'>{showRooms()}</Row>
                </Collapse>
                </Row></>
                : <p>NO ROOMS</p>}
            </Col>
        </Row>
        </>
    )
}

function CardLayout({theRoom, defaultProjectId, refetchSignal, setRefetchSignal})
{
    const cookies = new Cookies();
    const accesstoken = cookies.get("accessToken");
    const user_data = cookies.get("userData");
    const [defaultRoomChange, {error: setCurrentError}] = useMutation(SET_CURRENT_ROOM);

    const {data, loading, error, refetch} = useQuery(GET_ROOM_USERS, 
    {
        variables: {input: {roomId: theRoom.id}, id: user_data?.id},
        context: headerWithToken(accesstoken)
    });
    useEffect(() => {
        refetch();
    },[refetchSignal]) //eslint-disable-line

    if (setCurrentError){
        console.log(setCurrentError)
    }
    if (loading) return(<p>LOADING....</p>);
    if (error || !theRoom) {console.log(error.message); return ( <Card className="m-3 ms-1 me-1 roomCard" style={{maxWidth:'10rem'}}><Card.Title className='mt-1'>No Rooms</Card.Title></Card>);}

    const setCurrentRoom = async () => {
            try{
                await defaultRoomChange({
                    variables: {
                        "input": {
                            "userId": user_data.id,
                                  "defaultProjectId": defaultProjectId,
                                "newRoomId": theRoom.id
                            }
                      },
                    context: headerWithToken(accesstoken)})    
                //console.log(room);         
            }
            catch (excep)
            {
                //console.log()
                console.error(excep.message);
            }
            
            setRefetchSignal(!refetchSignal);
        }
        
  return(
  (theRoom.id ? 
    (<Card className="m-3 ms-1 me-1 roomCard" style={{maxWidth:'10rem'}} key={theRoom.id} id={theRoom.id}>
        <Card.Title className='mt-1'>{theRoom.id.substring(theRoom.id.length - 4)} <Card.Subtitle>Users: {data?.getRoomUsers.length}</Card.Subtitle></Card.Title>
        <Card.Body style={{textAlign:'center'}}>
            <Button className='btn-dark launchCardButton' onClick={setCurrentRoom} disabled={(data.getUserById.currentRoomId === theRoom.id)}>
                {/*icon*/}
                <LaunchIcon style={{fontSize:'2rem'}}></LaunchIcon>
            </Button>
        </Card.Body>
        
    </Card> )
    : <Card className="m-3 ms-1 me-1 roomCard" style={{maxWidth:'10rem'}} key={theRoom.id} id={theRoom.id}>
        <Card.Title className='mt-1'>No ID</Card.Title>
        </Card>)
    )
}
