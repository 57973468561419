import React from 'react';
import {gql, useMutation, useQuery} from '@apollo/client';
import { headerWithToken } from '../Helpers/utils';
import { Button } from 'react-bootstrap';


const GETREQUESTS = gql`query GetFriendRequest($userId: String!) {
    getFriendRequest(userId: $userId) {
      from {
        id
      }
    }
  }`
const SENDFRIENDREQUEST = gql`mutation SendFriendRequest($input: FriendInput) {
    sendFriendRequest(input: $input) {
      id
    }
  }`

  const FRIENDACCEPT = gql`mutation AddFriend($input: FriendInput) {
    addFriend(input: $input) {
      id
    }
  }`

const SendFriendRequest = ({userId, friendId, accesstoken}) =>{

  const {data, loading} = useQuery(GETREQUESTS, {variables: {"userId": userId}, context: headerWithToken(accesstoken)})

    
    const [sendFriendRequest] = useMutation(SENDFRIENDREQUEST, {
    })
    const [addFriend] = useMutation(FRIENDACCEPT, {
    })
    
    if(loading) return null;
    
    const sendRequest = (e) =>{
        if(data.getFriendRequest.some(request => request.from.id === friendId)) {addFriend({variables: {"input": {"acceptorId": userId, "requestorId": friendId}}, context: headerWithToken(accesstoken)})}
        else sendFriendRequest({variables: {"input": {"acceptorId": friendId, "requestorId": userId}}, context: headerWithToken(accesstoken)})
        window.location.reload(false);
    }

    
    return( 
        <Button onClick = {sendRequest}> Send Friend Request</Button>
    );
}
export default SendFriendRequest;