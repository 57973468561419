
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RfNavbar from '../Components/Navbar'

export default function RoomPage() {
    return (
        <>
        <Row className="defaultPage">
            <RfNavbar></RfNavbar>
            <br></br>
            <Row><Col style={{paddingTop: "50px", paddingBottom:'36rem', marginLeft:'20rem', textAlign:'left'}}><h1><b>My Rooms</b></h1></Col></Row>
            <br></br>
        </Row>
        </>
    )
}