
import {Row, Button,Col} from 'react-bootstrap';
import {gql, useMutation} from '@apollo/client'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const VERIFY = gql`mutation VerificationRequest($email: String!) {
    verificationRequest(email: $email) {
      message
      success
    }
  }`
  
  var firstFlag = false;
export default function VerifyPage() {
    const navigate = useNavigate();
    const [sendVerify, { data}] = useMutation(VERIFY)
    const email = localStorage.user_email
    //On first page render, we send an initial verification link.
    useEffect(() => {
        verifyRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

      //if their email is not stored in localStorage, we can assume they shouldn't be here and boot them back to the main page.
    if(!email){
        navigate('../');
        return ;
    }

      const verifyRequest = () =>{
        //console.log(email)
            sendVerify({variables: {"email": email}})
      }



      const sendNewLink = () =>{
        //This first flag is just so we don't display the link successfully sent when they haven't clicked the resend button. Might need some work.
        firstFlag = true;
        verifyRequest();
      }
    return (
        <>
        <Row className='defaultPage SignLoginMainRow'>
            <Col style={{display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:"rgba(17, 22, 35, 0)"}}>
                <Row>
                    <Col style={{backgroundColor:"rgba(9,9,9,0.6)",padding:'50px', borderRadius:'25px', color: "white", maxWidth: "500px"}}>
                        
                        <Row>
                        <Row><Col><h1><b>Welcome to RealityFlow</b></h1></Col></Row>
                        <br></br>
                        <Row><Col><h4>Email Verification</h4></Col></Row>
                        <br></br>
                        <Row><Col><p>A verification link has been sent to your email, please verify your email.</p> </Col></Row>
                        <Row><Col> {data && firstFlag && <div>Link successfully sent</div>} <Button href = "#" onClick = {sendNewLink}>Click here to resend email</Button></Col></Row>
                        <br></br>
                        <Row><Col><a href = "../login">Click here to return to login</a></Col></Row>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
        </>
    )
}