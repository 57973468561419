import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import RfNavbar from '../Components/Navbar';
import UserProjectsDisplay from '../Components/UserProjectsDisplay';
import { gql, useQuery } from '@apollo/client';
import { Cookies } from 'react-cookie';
import { Link, useNavigate } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { headerWithToken, kickUser } from '../Components/Helpers/utils';

const GETMYPROJECTS = gql`
  query GetUserById($getUserByIdId: String!) {
    getUserById(id: $getUserByIdId) {
      projectsOwned {
        id
        projectName
        gallery
      }
      projectsJoined {
        id
        projectName
        gallery
      }
      projectsCoOwned {
        id
        projectName
        gallery
      }
    }
  }
`;

export default function MyProjectPage() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const accesstoken = cookies.get('accessToken');
  const user_data = cookies.get('userData');

  useEffect(() => {
    if (!accesstoken || !user_data) {
      console.log('NO ACCESSTOKEN');
      navigate('/');
    }
  }, [accesstoken, user_data, navigate]);

  const { data, error } = useQuery(GETMYPROJECTS, {
    variables: { getUserByIdId: user_data ? user_data.id : null },
    context: headerWithToken(accesstoken),
  });

  useEffect(() => {
    if (error && error.message === 'Not Authenticated') {
      kickUser(navigate, cookies);
    }
  }, [error, navigate, cookies]);

  const myProjects = data ? data.getUserById.projectsOwned : [];
  const projectsJoined = data ? data.getUserById.projectsJoined : [];
  const projectsCoOwned = data ? data.getUserById.projectsCoOwned : [];

  const Item = ({ title, id, img }) => (
    <Link to={`/roomdetailpage?p=${id}`}>
      <div>
        <img
          className="d-block w-100"
          src={"https://via.placeholder.com/150"}
          alt={`slide ${id}`}
        />
        <h3>{title}</h3>
      </div>
    </Link>
  );

  return (
    <>
      <RfNavbar />
      <Tabs className="tabulo">
        <Tab eventKey="myprojects" title="My Projects">
          <Row style={{ minHeight: '100vh' }}>
            <Col className="defaultPage" style={{ padding: '5%' }}>
              <Row style={{ marginBottom: '10%' }}>
                <Col>
                  <h1>My Projects</h1>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'right' }}>
                  <Link to="/createproject">
                    <Button style={{ maxHeight: '80%' }}>Create New Project</Button>
                  </Link>
                </Col>
              </Row>
              {myProjects.length > 0 ? (
                <UserProjectsDisplay colCount={3}>
                  {myProjects.map((item) => (
                    <Item key={item.id} id={item.id} title={item.projectName} img={item.gallery[0]} />
                  ))}
                </UserProjectsDisplay>
              ) : (
                <p>
                  No projects are found. <Link to="/createproject">Click Here</Link> to create one.
                </p>
              )}
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="joined" title="Joined Projects">
          <Row style={{ minHeight: '100vh' }}>
            <Col className="defaultPage" style={{ padding: '5%' }}>
              <Row style={{ marginBottom: '10%' }}>
                <Col>
                  <h1>Joined Projects</h1>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'right' }}>
                  <Link to="/createproject">
                    <Button style={{ maxHeight: '80%' }}>Create New Project</Button>
                  </Link>
                </Col>
              </Row>
              {projectsJoined.length > 0 ? (
                <UserProjectsDisplay colCount={3}>
                  {projectsJoined.map((item) => (
                    <Item key={item.id} id={item.id} title={item.projectName} img={item.gallery[0]} />
                  ))}
                </UserProjectsDisplay>
              ) : (
                <p>
                  No projects are found. <Link to="/createproject">Click Here</Link> to create one.
                </p>
              )}
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="owned" title="Co-Owned Projects">
          <Row style={{ minHeight: '100vh' }}>
            <Col className="defaultPage" style={{ padding: '5%' }}>
              <Row style={{ marginBottom: '10%' }}>
                <Col>
                  <h1>Co-Owned Projects</h1>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'right' }}>
                  <Link to="/createproject">
                    <Button style={{ maxHeight: '80%' }}>Create New Project</Button>
                  </Link>
                </Col>
              </Row>
              {projectsCoOwned.length > 0 ? (
                <UserProjectsDisplay colCount={3}>
                  {projectsCoOwned.map((item) => (
                    <Item key={item.id} id={item.id} title={item.projectName} img={item.gallery[0]} />
                  ))}
                </UserProjectsDisplay>
              ) : (
                <p>
                  No projects are found. <Link to="/createproject">Click Here</Link> to create one.
                </p>
              )}
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </>
  );
}
