import {Row, Col, Button, Form} from 'react-bootstrap';
import editIcon from '../Images/editIcon.png';
import RfNavbar from './Navbar';
import ProfilePicture from './ProfilePicture';

const SettingsLoading = () =>{

    return (
        <>
            <RfNavbar/>
        <Form>
            <Row className="defaultPage" style={{padding:'5%'}}>
                
                <Col style={{paddingBottom:'15%'}}>
                    
                    <Row  style={{textAlign:'center', paddingBottom:'7%'}}>
                        <Form.Group  as={Col} controlId="userProfilePic">
                            <Button className="rounded-circle profilePicButton" style={{background: "#FFFFFF"}}><ProfilePicture profileImg={'#FFFFFF Smile.png'} useClass={'accountImageSettings2'} /></Button>
                        
                        </Form.Group >
                        <Col style={{maxWidth:'40%'}}>
                        <Row >
                            <Col >
                                <h2>About Me</h2>
                                <Form.Group controlId="aboutMe">
                                <Form.Control required size="md" as="textarea" type="text" name="about" rows={12} defaultValue = {"Bio"} className='editText' style={{textAlign:'left', marginBottom:'2%'}}>
                                </Form.Control>
                                </Form.Group>
                            </Col>
                            </Row>
                        </Col>
                    </Row>
                    <svg className="settingsVector"></svg>
                    <Row style={{paddingTop:'1%', paddingBottom:'2%'}}>
                        <Col>
                        
                            <Row style={{paddingBottom:'1%'}}>
                                <Col style={{textAlign:'left'}}><h2>My account</h2></Col>
                            </Row>
                            <svg className="settingsVector"></svg>
                            <Row>
                                <Col >
                                    <Row style={{paddingTop:'3%'}}>
                                        <Col><p>NAME</p></Col>
                                        <Col><p>First Last</p></Col>
                                    </Row>
                                    <Row style={{marginTop:'2%'}}>
                                        <Col><p>USERNAME</p></Col>
                                        <Col><p>username</p></Col>
                                    </Row>
                                    <Row style={{marginTop:'2%'}}>
                                        <Col><p>EMAIL ADDRESS</p></Col>
                                        <Col className="text-truncate"><p>email@email.com</p></Col>
                                    </Row>
                                </Col>
                                <Col style={{maxWidth:'20%', paddingTop:'2%'}}>
                                    <Row style={{marginTop:'3%'}}><Col><Button className="settingsButton1"><img alt="edit-icon" src={editIcon} style={{width:'20px', height:'20px'}}/></Button></Col></Row>
                                    {/*<Row style={{marginTop:'3%'}}><Col><Button className="settingsButton1" onClick={onUserNameClick}><img src={editIcon} style={{width:'20px', height:'20px'}}/></Button></Col></Row>*/}
                                    <Row><Col></Col></Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col></Col>
                                <Col>
                                    <Button className = "defaultButton" variant="primary" type="submit" style={{marginTop:'10%', marginBottom:'2%'}}>
                                    Save
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
        </>
    )
}
export default SettingsLoading